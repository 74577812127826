import { instanceAxios, instanceWithoutAxios } from "./axios";
import { instanceAxiosMultipart } from './axiosMultipart'

export const post = (url, data = {}) => {
  return instanceAxios.post(url, data);
};
export const postMultipart = (url, data = {}, header) =>
  instanceAxiosMultipart.post(url, data, header)

export const postWithoutHeader = (url, data = {}) => {
  return instanceWithoutAxios.post(url, data);
};

export const get = (url, params = {}) => {
  return instanceAxios.get(encodeURI(url), { params });
}
export const getWithdata = (url, data = {}) => {
  return instanceAxios.get(url, data);
};
export const getWithoutHeader = (url, params = {}) => {
  return instanceWithoutAxios.get(encodeURI(url), { params });
};

export const put = (url, data = {}) => instanceAxios.put(url, data);
export const deleteApi = (url, data = {}) => instanceAxios.delete(url, data);
