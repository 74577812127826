import { getPaylinkAPI } from "apiServices/paylinks";
import React, { useEffect, useState } from "react";
import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { getStorageData } from "utils/helper";
import DashboardComponent from "components/Dashboard/DashboardComponent";
import DashTableComponent from "components/Dashboard/DashTableComponent";

const DashboardContainer = () => {
  const [showLoader, setShowLoader] = useState(false);
  const walletEmail = getStorageData("pocketpay:email");
  const [orderList, setOrderList] = useState([]);
  const [manageList, setManageList] = useState({});

  const listofPaylinks = (walletEmail) => {
    setShowLoader(true);
    try {
      getPaylinkAPI().then((response) => {
        const responseData = response.data || response;
        if (responseData !== null && responseData.success !== false) {
          const allOrders = responseData.filter(
            (order) =>
              order.order_type === "Paylinks" || order.order_type === "POS"
          );
          const manageOrders = allOrders.filter(
            (order) => order.status !== "pending"
          );
          setOrderList(manageOrders.reverse());

          const uniqueClientAddresses = new Set(
            manageOrders.map((order) => order.client_address)
          );
          const totalUniqueAddresses = uniqueClientAddresses.size;

          const totalCombinedAmount = manageOrders.reduce(
            (total, order) => total + order.amount,
            0
          );
          const paylinkOrder = manageOrders.filter(
            (order) => order.order_type === "Paylinks"
          );
          const posOrder = manageOrders.filter(
            (order) => order.order_type === "POS"
          );
          setManageList({
            orderCount: manageOrders.length,
            orderAmount: totalCombinedAmount,
            totalUniqueUsers: totalUniqueAddresses,
            paylinkOrderCount: paylinkOrder.length,
            posOrderCount: posOrder.length,
          });
          setShowLoader(false);
        } else {
          setShowLoader(false);
        }
      });
    } catch (error) {
      setShowLoader(false);
    }
  };

  useEffect(() => {
    if (walletEmail !== null || walletEmail !== undefined) {
      listofPaylinks(walletEmail);
    }
  }, [walletEmail]);

  return (
    <div className="content">
      {showLoader ? (
        <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />
      ) : (
        <>
          <DashboardComponent orderList={orderList} manageList={manageList} />
          <DashTableComponent orderList={orderList} />
        </>
      )}
    </div>
  );
};

export default DashboardContainer;
