import React from "react";
import { Drawer } from "antd";
import classNames from "classnames";
import { useStyles } from "./style";

const CustomDrawerComponent = ({
  label,
  onOpen,
  ontoggle,
  extraClass,
  children,
  drawerSizing,
}) => {
  const classes = useStyles();
  return (
    <Drawer
      title={label}
      open={onOpen}
      onClose={ontoggle}
      width={drawerSizing}
      className={classNames(extraClass, classes.customDrawer)}
      footer=""
    >
      {children}
    </Drawer>
  );
};

export default CustomDrawerComponent;
