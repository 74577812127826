import { createUseStyles } from "react-jss";
import imgHolder from "assets/img/img__holder.svg";
export const useStyles = createUseStyles({
  imgUpload: {
    "& .iuLe": {
      width: "80px",
      height: "80px",
      position: "relative",
      borderRadius: "50rem",
      backgroundImage: `url(${imgHolder}) !important`,
      backgroundPosition: "center",
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover",
      "& .iuImgRemove": {
        position: "absolute",
        bottom: "0",
        right: "0",
        zIndex: "2",
        cursor: "pointer",
        width: "28px",
        height: "28px",
        borderRadius: "50rem",
        backgroundColor: "#f9ebeb",
        border: "2px solid var(--white)",
        boxShadow: "0 1px 15px 0 rgba(123, 123, 123, 0.15)",
        "& svg": {
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          WebkitTransform: "translate(-50%, -50%)",
          "& > path": {
            fill: "red",
          },
        },
      },
      "& .iuLImg": {
        width: "100%",
        height: "100%",
        objectFit: "cover",
        borderRadius: "50rem",
      },
    },
    "& .iuRi": {
      flex: "1",
      paddingLeft: "1rem",
      "& .iuUploadBtn": {
        position: "relative",
        "& > button": {
          height: "46px",
          paddingLeft: "16px",
          paddingRight: "16px",
          marginBottom: "4px",
        },
      },
    },
  },
});
