import React, { useState } from "react";
import { Line } from "react-chartjs-2";
import { Card, CardHeader, CardBody, CardTitle } from "reactstrap";
import { chart_1_2_3_options } from "./constant";
import { Row, Col } from "antd";

const DashboardComponent = ({ orderList, manageList }) => {
  const [bigChartData, setbigChartData] = useState("data1");

  // Initialize a two-dimensional array to store monthly order counts for each year
  const allYearlyOrderCounts = [];
  // Iterate through orders and count orders for each month and year
  orderList &&
    orderList.forEach((order) => {
      const orderDate = new Date(order.date_time);
      const year = orderDate.getFullYear();
      const month = orderDate.getMonth();

      // If the array for the year does not exist, create it
      if (!allYearlyOrderCounts[year]) {
        allYearlyOrderCounts[year] = Array(12).fill(0);
      }

      // Increment the count for the corresponding month and year
      allYearlyOrderCounts[year][month]++;
    });

  // Create a new array for each sub-array without null values
  const newArray = allYearlyOrderCounts.map((subArray) =>
    subArray.filter((value) => value !== null)
  );
  const filteredArray = newArray.filter((subArray) => subArray.length > 0);

  let chartExample1 = {
    data1: (canvas) => {
      let ctx = canvas.getContext("2d");
      let gradientStroke = ctx.createLinearGradient(0, 230, 0, 50);
      gradientStroke.addColorStop(1, "rgba(29,140,248,0.2)");
      gradientStroke.addColorStop(0.4, "rgba(29,140,248,0.0)");
      gradientStroke.addColorStop(0, "rgba(29,140,248,0)"); //blue colors

      return {
        labels: [
          "JAN",
          "FEB",
          "MAR",
          "APR",
          "MAY",
          "JUN",
          "JUL",
          "AUG",
          "SEP",
          "OCT",
          "NOV",
          "DEC",
        ],
        datasets: [
          {
            label: "Total Records",
            fill: true,
            backgroundColor: gradientStroke,
            borderColor: "#1f8ef1",
            borderWidth: 2,
            borderDash: [],
            borderDashOffset: 0.0,
            pointBackgroundColor: "#1f8ef1",
            pointBorderColor: "rgba(255,255,255,0)",
            pointHoverBackgroundColor: "#1f8ef1",
            pointBorderWidth: 20,
            pointHoverRadius: 4,
            pointHoverBorderWidth: 15,
            pointRadius: 4,
            data: filteredArray[0],
          },
        ],
      };
    },
    options: chart_1_2_3_options,
  };

  return (
    <Row gutter={{ xs: 20, sm: 20, md: 20, lg: 20 }}>
      <Col
        className="gutter-row"
        span={8}
        xs={{ span: 24 }}
        sm={{ span: 24 }}
        md={{ span: 12 }}
        lg={{ span: 8 }}
      >
        <Card className="card-stats padd20">
          <CardBody className="px-0 py-3">
            <div className="d-flex align-items-center justify-content-between">
              <div className="info-icon text-center icon-primary">
                <i className="tim-icons icon-paper" />
              </div>
              <div className="numbers">
                <CardTitle tag="h3" className="mb-0">
                  {manageList && manageList.orderCount}
                </CardTitle>
                <p className="card-category">Total Records</p>
              </div>
            </div>
          </CardBody>
        </Card>
      </Col>
      <Col
        className="gutter-row"
        span={8}
        xs={{ span: 24 }}
        sm={{ span: 24 }}
        md={{ span: 12 }}
        lg={{ span: 8 }}
      >
        <Card className="card-stats padd20">
          <CardBody className="px-0 py-3">
            <div className="d-flex align-items-center justify-content-between">
              <div className="info-icon text-center icon-warning">
                <i className="tim-icons icon-single-02" />
              </div>
              <div className="numbers">
                <CardTitle tag="h3" className="mb-0">
                  {manageList && manageList.totalUniqueUsers}
                </CardTitle>
                <p className="card-category">Total Users</p>
              </div>
            </div>
          </CardBody>
        </Card>
      </Col>
      <Col
        className="gutter-row"
        span={8}
        xs={{ span: 24 }}
        sm={{ span: 24 }}
        md={{ span: 12 }}
        lg={{ span: 8 }}
      >
        <Card className="card-stats padd20">
          <CardBody className="px-0 py-3">
            <div className="d-flex align-items-center justify-content-between">
              <div className="info-icon text-center icon-success">
                <i className="tim-icons icon-coins" />
              </div>
              <div className="numbers">
                <CardTitle tag="h3" className="mb-0">
                  {manageList &&
                    manageList.orderAmount &&
                    manageList.orderAmount.toFixed(2)}
                </CardTitle>
                <p className="card-category">Total Amount</p>
              </div>
            </div>
          </CardBody>
        </Card>
      </Col>
      <Col className="gutter-row" span={24}>
        <Card className="card-chart">
          <CardHeader className="d-flex align-items-center justify-content-between">
            <CardTitle tag="h4">Performance</CardTitle>
            <p className="mb-0">2024</p>
          </CardHeader>
          <CardBody>
            <div className="chart-area">
              <Line
                data={chartExample1[bigChartData]}
                options={chartExample1.options}
              />
            </div>
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
};

export default DashboardComponent;
