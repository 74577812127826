import React, { useEffect, useState } from "react";
import { Select, Spin, Tooltip } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  FormGroup,
  Form,
  Input,
} from "reactstrap";
import {
  getSpecificMerchantSetting,
  updateEndTimeStatus,
} from "apiServices/setting";
import { toastMessage, getStorageData, useGlobalMerchant } from "utils/helper";
import ButtonComponent from "components/FormComponents/ButtonComponent";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { Email } from "utils/helper";

const GeneralComponent = () => {
  const { Option } = Select;
  const walletEmail = getStorageData("pocketpay:email");
  const getUserToken = getStorageData("pocketpay:user");
  const { globalMerchantId } = useGlobalMerchant();
  const [merchantId, setMerchantId] = useState("");
  const [showLoader, setShowLoader] = useState(false);
  const [isUpdateProfile, setIsUpdateProfile] = useState(false);
  const [onBtnLoader, setOnBtnLoader] = useState(false);
  // const [selectedItems, setSelectedItems] = useState([]);
  // const options = ["POS", "Terminal", "Paylink", "Finder"];
  const [formData, setFormData] = useState({
    general_email: "",
    noti_email: "",
    end_time: "",
  });

  // const handleSelectionChange = (selectedValues) => {
  //   setSelectedItems(selectedValues);
  // };

  const onUpdateUsersData = async () => {
    if (
      formData.end_time !== "" &&
      formData.general_email !== "" &&
      formData.noti_email !== "" &&
      formData.noti_email !== null
    ) {
      const verifyEmail = Email(formData.noti_email);
      if (verifyEmail) {
        toastMessage("Please fillup email proper");
        return;
      }
      if (merchantId !== "") {
        // if (!file) {
        //   toastMessage("Please select an image first.");
        //   return;
        // }
        if (walletEmail !== null && getUserToken !== null) {
          setOnBtnLoader(true);
          const newObject = {
            merchant_id: merchantId,
            noti_email: formData.noti_email,
            end_time: formData.end_time,
          };
          try {
            updateEndTimeStatus(newObject)
              .then((response) => {
                toastMessage("updated successfully");
                setIsUpdateProfile(true);
                setOnBtnLoader(false);
              })
              .catch((error) => {});
          } catch (error) {
            toastMessage("something went wrong");
          }
        }
      }
    } else {
      toastMessage("All fields are required!");
    }
  };

  const onGetUsersData = (getKey) => {
    try {
      setShowLoader(true);
      getSpecificMerchantSetting(getKey).then((response) => {
        const responseData = response.data || response;
        if (responseData !== null && responseData.success !== false) {
          setMerchantId(getKey);
          setFormData({
            ...formData,
            general_email: response.merchant_email,
            end_time: responseData.end_time,
            noti_email: responseData.noti_email,
          });
          setShowLoader(false);
        }
      });
    } catch (error) {
      setShowLoader(false);
    }
  };

  useEffect(() => {
    if (
      walletEmail !== null &&
      globalMerchantId !== null &&
      globalMerchantId !== undefined
    ) {
      onGetUsersData(globalMerchantId);
    }
  }, [globalMerchantId, isUpdateProfile]);

  return (
    <Card className="mb-0 h-100">
      <CardHeader>
        <CardTitle tag="h4">General Settings</CardTitle>
      </CardHeader>
      {showLoader ? (
        <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />
      ) : (
        <>
          <CardBody>
            <Form action="#">
              <FormGroup>
                <label>
                  Email<span className="text-danger ml-1">*</span>
                </label>
                <Input
                  type="text"
                  placeholder="Enter Email"
                  value={formData.general_email}
                  disabled
                />
              </FormGroup>
              <FormGroup>
                <label>
                  Notification Email<span className="text-danger ml-1">*</span>
                </label>
                <Input
                  type="text"
                  placeholder="Enter Notification Email"
                  value={formData.noti_email}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      noti_email: e.target.value,
                    })
                  }
                />
              </FormGroup>
              <FormGroup>
                <label>
                  QR Expiry Time (In Seconds)
                  <span className="text-danger ml-1">*</span>
                </label>
                <Input
                  type="number"
                  placeholder="45"
                  value={formData.end_time}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      end_time: e.target.value,
                    })
                  }
                />
              </FormGroup>
              {/* <FormGroup>
                <label>Payment Selection</label>
                <Select
                  mode="multiple"
                  placeholder="Select options"
                  value={selectedItems}
                  onChange={handleSelectionChange}
                  className="w-100"
                >
                  {options.map((option) => (
                    <Option key={option} value={option}>
                      {option}
                    </Option>
                  ))}
                </Select>
              </FormGroup> */}
            </Form>
          </CardBody>
          <CardFooter className="text-right">
            <Tooltip title="Update Settings" className="cursorPointer">
              <ButtonComponent
                extraClass="svgAnimationYX"
                btnPrimary
                onClick={() => onUpdateUsersData()}
              >
                {"Update Settings"}
                {onBtnLoader ? (
                  <LoadingOutlined
                    style={{ width: "16", height: "16" }}
                    className="ml-2"
                  />
                ) : (
                  <FontAwesomeIcon
                    style={{ width: "16", height: "16" }}
                    className="aniArrow ml-2"
                    icon={faArrowRight}
                  />
                )}
              </ButtonComponent>
            </Tooltip>
          </CardFooter>
        </>
      )}
    </Card>
  );
};

export default GeneralComponent;
