import axios from 'axios'
import { baseURL } from 'utils/env'
import { serverErrors } from './handleServerError'

const commonHeaders = {
  'Content-Type': 'multipart/form-data',
  Accept: 'application/json',
}
const getHeader = () => {
  const user = localStorage.getItem('pocketpay:user')
  if (user && user.token) {
    return { ...commonHeaders, ...{ Authorization: 'Bearer ' + user.token } }
  }
}
export const instanceAxiosMultipart = axios.create({
  baseURL,
  headers: getHeader(),
})

instanceAxiosMultipart.interceptors.response.use(
  (response) => successResponce(response),
  (error) => handleErrors(error)
)

instanceAxiosMultipart.interceptors.request.use(
  (config) => {
    config.headers = getHeader()
    return config
  },
  (error) => {
    return Promise.reject(error)
  }
)
const successResponce = (result) => {
  const { data = {} } = result
  return data
}
const handleErrors = (error) => {
  if (
    error &&
    error.response &&
    error.response.status &&
    error.response.status === 403
  ) {
    window.localStorage.clear()
    window.location = '/login'
  }
  return Promise.reject(serverErrors(error))
}
