import React from "react";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import { useStyles } from "./style";
import classNames from "classnames";

const CustomModalComponent = ({
  isOpen,
  toggle,
  isFormModal,
  modalTitle,
  children,
  extraClass,
}) => {
  const classes = useStyles();
  return (
    <Modal
      className={classNames(extraClass, classes.customModal)}
      isOpen={isOpen}
      backdrop="static"
      toggle={toggle}
      // size="lg"
    >
      {isFormModal ? (
        <ModalHeader toggle={toggle}>{modalTitle}</ModalHeader>
      ) : null}
      <ModalBody>{children}</ModalBody>
    </Modal>
  );
};

export default CustomModalComponent;
