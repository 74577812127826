import { GetUnVerifyUser } from "utils/URLs";
import { PostAllowUser } from "utils/URLs";
import { Login, Signup, Users } from "utils/URLs";
import { post } from "utils/v2/api";
import { get, postWithoutHeader } from "utils/v2/api";

export const login = (data) => postWithoutHeader(`${Login}`, data);
export const signup = (data) => postWithoutHeader(`${Signup}`, data);
export const getUsers = () => get(`${Users}`);

export const getVerifyUser = () => get(`${GetUnVerifyUser}`);
export const postAllowUser = (data) => post(`${PostAllowUser}`, data);
