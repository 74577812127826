import React, { useState } from "react";
import { CardTitle, Label, FormGroup, Form, Input } from "reactstrap";
import { Select, Tooltip } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowRight,
  faCheckCircle,
  faThumbsUp,
  faInfoCircle,
} from "@fortawesome/free-solid-svg-icons";
import { ToastContainer } from "react-toastify";
import { getCountryDataList } from "countries-list";
import { signup } from "apiServices/app";
import { toastMessage, Email } from "utils/helper";
import CustomModalComponent from "components/FormComponents/CustomModalComponent";
import ButtonComponent from "components/FormComponents/ButtonComponent";

const RegisterComponent = ({ setShowRegister }) => {
  const { Option } = Select;
  const countryList = getCountryDataList();
  const [isChecked, setIsChecked] = useState(false);
  const [showInfoModal, setShowInfoModal] = useState(false);
  const [btnLoader, setBtnLoader] = React.useState(false);
  const [formData, setFormData] = useState({
    username: "",
    email: "",
    country: "",
    telegram: "",
    discord: "",
  });

  const onClose = () => {
    setShowInfoModal(false);
    setShowRegister(false);
  };

  const onChange = (getValue) => {
    setFormData({ ...formData, country: getValue });
  };

  const onStartWithId = (getName, getValue) => {
    if (getName === "telegram") {
      const verifyData =
        getValue &&
        (getValue.startsWith("@") || getValue.startsWith("https://t.me/"));
      return verifyData;
    }
    if (getName === "discord") {
      const verifyData = getValue && getValue.startsWith("@");
      return verifyData;
    }
  };

  const handleSignup = async () => {
    const verifyConditions =
      isChecked &&
      formData.email !== "" &&
      formData.username !== "" &&
      formData.country !== "" &&
      formData.telegram !== "" &&
      formData.discord !== "";

    if (verifyConditions) {
      const verifyEmail = Email(formData.email);
      if (verifyEmail) {
        toastMessage("Please fillup correct email");
        return;
      }
      const verifyTelegram = onStartWithId("telegram", formData.telegram);
      if (!verifyTelegram) {
        toastMessage("Please fillup correct telegram id");
        return;
      }
      const verifyDiscord = onStartWithId("discord", formData.discord);
      if (!verifyDiscord) {
        toastMessage("Please fillup correct discord id");
        return;
      }

      try {
        setBtnLoader(true);
        signup(formData)
          .then((response) => {
            setBtnLoader(false);
            setShowInfoModal(true);
          })
          .catch((error) => {
            setBtnLoader(false);
            toastMessage("Username or email already exists!");
          });
      } catch (error) {
        setBtnLoader(false);
        toastMessage(error);
      }
    } else {
      toastMessage("All fields are required!");
    }
  };

  React.useEffect(() => {
    document.body.classList.toggle("accountPage");
    return function cleanup() {
      document.body.classList.toggle("accountPage");
    };
  });

  return (
    <>
      <ToastContainer />
      <Form autoComplete="off">
        <div className="formTitle mb-3 mb-md-5">
          <CardTitle className="mb-2 font-weight-semibold" tag="h2">
            Sign Up
          </CardTitle>
          <span>Give me some of your information for sign up.</span>
        </div>
        <FormGroup>
          <Label>
            Full Name<span className="text-danger ml-1">*</span>
          </Label>
          <Input
            type="text"
            autocomplete="new-username"
            placeholder="Enter full name"
            value={formData.username}
            onChange={(e) =>
              setFormData({ ...formData, username: e.target.value })
            }
          />
        </FormGroup>
        <FormGroup>
          <Label>
            Email Address<span className="text-danger ml-1">*</span>
          </Label>
          <Input
            type="email"
            autocomplete="new-email"
            placeholder="Enter email"
            value={formData.email}
            onChange={(e) =>
              setFormData({ ...formData, email: e.target.value })
            }
          />
        </FormGroup>
        <FormGroup>
          <Label>
            Country<span className="text-danger ml-1">*</span>
          </Label>
          <Select
            showSearch
            placeholder="Select country"
            onChange={(value) => onChange(value.toString())}
            className="w-100"
            autoComplete="new-state"
          >
            {countryList.map((country) => (
              <Option key={country.name} value={country.name}>
                {country.name}
              </Option>
            ))}
          </Select>
        </FormGroup>
        <FormGroup>
          <Label className="d-flex">
            Telegram ID<span className="text-danger ml-1">*</span>
            <div>
              <Tooltip
                className="offset-2"
                title="Telegram ID start with @ or https://t.me/"
                trigger="click"
              >
                <FontAwesomeIcon icon={faInfoCircle} />
              </Tooltip>
            </div>
          </Label>
          <Input
            type="text"
            placeholder="Enter telegram id"
            value={formData.telegram}
            onChange={(e) =>
              setFormData({ ...formData, telegram: e.target.value })
            }
          />
        </FormGroup>
        <FormGroup>
          <Label className="d-flex">
            Discord ID<span className="text-danger ml-1">*</span>
            <div>
              <Tooltip
                className="offset-2"
                title="Discord ID start with @"
                trigger="click"
              >
                <FontAwesomeIcon icon={faInfoCircle} />
              </Tooltip>
            </div>
          </Label>
          <Input
            type="text"
            placeholder="Enter discrord id"
            value={formData.discord}
            onChange={(e) =>
              setFormData({ ...formData, discord: e.target.value })
            }
          />
        </FormGroup>
        <FormGroup check>
          <Label check>
            <Input type="checkbox" onClick={() => setIsChecked(!isChecked)} />
            <span className="form-check-sign" />I agree to the
            <a className="ml-1" href="#pablo">
              terms and conditions
            </a>
            .
          </Label>
        </FormGroup>
      </Form>
      <div className="mt-4 mb-3">
        <Tooltip title="Sign Up" className="cursorPointer">
          <ButtonComponent
            extraClass="svgAnimationYX w-100"
            btnPrimaryFill
            onClick={() => handleSignup()}
          >
            {"Sign Up"}
            {btnLoader ? (
              <LoadingOutlined className="spinLader ml-2" />
            ) : (
              <FontAwesomeIcon
                style={{ width: "16", height: "16" }}
                className="aniArrow ml-2"
                icon={faArrowRight}
              />
            )}
          </ButtonComponent>
        </Tooltip>
      </div>
      <div className="text-center">
        <span className="font-weight-semibold">
          Already have an account?
          <span
            title="Sign In"
            className="cursorPointer text-primary linkHover ml-1"
            onClick={() => setShowRegister(false)}
          >
            Sign In
          </span>
        </span>
      </div>
      <CustomModalComponent
        isOpen={showInfoModal}
        toggle={() => onClose()}
        isFormModal={false}
      >
        <div className="py-2 px-1">
          <div className="text-center">
            <FontAwesomeIcon
              className="text-success mb-3"
              icon={faCheckCircle}
              style={{ fontSize: "60", height: "60" }}
            />
            <h3 className="mb-2">
              <strong className="text-success">
                Registration successfully completed!
              </strong>
            </h3>
            <h4 className="mb-4">
              You may able to login once the admin approve the request & You
              will receive an email for the same.
            </h4>
            <h4 className="mb-4">
              <strong className="text-success">
                Good luck! <FontAwesomeIcon icon={faThumbsUp} />
              </strong>
            </h4>
          </div>
          <div className="d-flex align-items-center justify-content-center">
            <Tooltip title="Continue to Login" className="cursorPointer">
              <ButtonComponent
                extraClass="svgAnimationYX"
                btnPrimaryFill
                onClick={() => setShowRegister(false)}
              >
                {"Continue to Login"}
                {btnLoader ? (
                  <LoadingOutlined className="spinLader ml-2" />
                ) : (
                  <FontAwesomeIcon
                    style={{ width: "16", height: "16" }}
                    className="aniArrow ml-2"
                    icon={faArrowRight}
                  />
                )}
              </ButtonComponent>
            </Tooltip>
          </div>
        </div>
      </CustomModalComponent>
    </>
  );
};

export default RegisterComponent;
