import DashboardContainer from "Container/DashboardContainer";
import KeyContainer from "Container/KeyContainer";
// import FinderContainer from "Container/FinderContainer";
// import MintContainer from "Container/MintContainer";
import OrdersContainer from "Container/OrdersContainer";
import POSContainer from "Container/POSContainer";
import PaylinkContainer from "Container/PaylinkContainer";
import SettingContainer from "Container/SettingContainer";

const routes = [
  {
    path: "/dashboard",
    name: "Dashboard",
    rtlName: "لوحة القيادة",
    icon: "fas fa-chart-pie",
    component: <DashboardContainer />,
    layout: "/admin",
  },
  {
    path: "/orders",
    name: "Orders",
    rtlName: "الحاجيات",
    icon: "fas fa-shopping-cart",
    component: <OrdersContainer />,
    layout: "/admin",
  },
  {
    path: "/pos",
    name: "POS",
    rtlName: "الحاجيات",
    icon: "fas fa-laptop",
    component: <POSContainer />,
    layout: "/admin",
  },
  {
    path: "/checkout",
    name: "Checkout",
    rtlName: "الحاجيات",
    icon: "fas fa-link",
    component: <PaylinkContainer />,
    layout: "/admin",
  },
  // {
  //   path: "/finder",
  //   name: "Finder",
  //   rtlName: "الحاجيات",
  //   icon: "fas fa-search",
  //   component: <FinderContainer />,
  //   layout: "/admin",
  // },
  // {
  //   path: "/nft",
  //   name: "NFT",
  //   rtlName: "الحاجيات",
  //   icon: "fas fa-dollar-sign",
  //   component: <MintContainer />,
  //   layout: "/admin",
  // },
  {
    path: "/keys",
    name: "My Keys",
    rtlName: "الحاجيات",
    icon: "fas fa-key",
    component: <KeyContainer />,
    layout: "/admin",
  },
  {
    path: "/settings",
    name: "Settings",
    rtlName: "الحاجيات",
    icon: "fas fa-cog",
    component: <SettingContainer />,
    layout: "/admin",
  },
];

export default routes;
