import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { faCloudUpload, faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ButtonComponent from "../ButtonComponent";
import { baseImageURL } from "utils/env";
import { useStyles } from "./style";
import imgHolder from "assets/img/img__holder.svg";
import defaultImage from "assets/img/image_placeholder.jpg";

const ImageUploadComponent = ({
  theme,
  dynamicImage,
  dynamicImageText,
  onFileSelected,
}) => {
  const classes = useStyles({ theme });
  const fileInput = React.useRef(null);
  const [file, setFile] = React.useState(null);
  const [imagePreviewUrl, setImagePreviewUrl] = React.useState(
    file === null && defaultImage
  );

  const handleImageChange = (e) => {
    e.preventDefault();
    if (e.target.files && e.target.files.length > 0) {
      let reader = new FileReader();
      let file = e.target.files[0];

      reader.onloadend = () => {
        setFile(file);
        setImagePreviewUrl(reader.result);
        onFileSelected(file); // Call the passed down function with the new file
      };
      reader.readAsDataURL(file);
    }
  };

  // const handleClick = () => {
  //   fileInput.current.click();
  // };

  // eslint-disable-next-line
  // const handleSubmit = (e) => {
  //   e.preventDefault();
  //   // file is the file/image uploaded
  //   // in this function you can save the image (file) on form submit
  //   // you have to call it yourself
  // };

  const handleRemove = () => {
    setFile(null);
    setImagePreviewUrl(defaultImage);
    fileInput.current.value = null;
  };

  return (
    <div className={classNames(classes.imgUpload, "d-flex align-items-center")}>
      <div className="iuLe">
        {file !== null && (
          <div className="iuImgRemove" onClick={() => handleRemove()}>
            <FontAwesomeIcon icon={faTrashAlt} />
          </div>
        )}
        <img
          className="iuLImg"
          width={50}
          height={50}
          alt={dynamicImageText}
          src={
            file !== null
              ? imagePreviewUrl
              : dynamicImage !== null && dynamicImage !== undefined
              ? `${baseImageURL}${dynamicImage}`
              : imgHolder
          }
        />
      </div>
      <div className="iuRi">
        <div className="iuUploadBtn">
          <ButtonComponent className="svgAnimationY" btnPrimary>
            <input type="file" onChange={handleImageChange} ref={fileInput} />
            <FontAwesomeIcon className="mr-2" icon={faCloudUpload} />
            Upload Image
          </ButtonComponent>
        </div>
        <span className="d-inline-block lineHeightNormal">
          The image should be under 2MB and in JPG, JPEG, or PNG format.
        </span>
      </div>
    </div>
  );
};

ImageUploadComponent.defaultProps = {
  avatar: false,
  removeBtnClasses: "btn-round",
  removeBtnColor: "danger",
  addBtnClasses: "btn-round",
  addBtnColor: "primary",
  changeBtnClasses: "btn-round",
  changeBtnColor: "primary",
  onFileSelected: PropTypes.func, // Add this line
};

ImageUploadComponent.propTypes = {
  avatar: PropTypes.bool,
  dynamicImage: PropTypes.any,
  dynamicImageText: PropTypes.string,
  removeBtnClasses: PropTypes.string,
  removeBtnColor: PropTypes.oneOf([
    "default",
    "primary",
    "secondary",
    "success",
    "info",
    "warning",
    "danger",
    "link",
  ]),
  addBtnClasses: PropTypes.string,
  addBtnColor: PropTypes.oneOf([
    "default",
    "primary",
    "secondary",
    "success",
    "info",
    "warning",
    "danger",
    "link",
  ]),
  changeBtnClasses: PropTypes.string,
  changeBtnColor: PropTypes.oneOf([
    "default",
    "primary",
    "secondary",
    "success",
    "info",
    "warning",
    "danger",
    "link",
  ]),
};

export default ImageUploadComponent;
