import React, { useState } from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { getStorageData } from "utils/helper";
import { GlobalMerchantProvider } from "utils/helper";
import { merchantAddress } from "utils/env";
import SignContainer from "Container/SignContainer";
import ApproveLayout from "views/layouts/Approve/Approve";
import Admin from "views/layouts/Admin/Admin";
import AuthLayout from "views/layouts/Auth/Auth.js";
import RTLLayout from "views/layouts/RTL/RTL.js";

function App() {
  const [walletEmail, setWalletEmail] = useState(
    getStorageData("pocketpay:email")
  );

  return (
    <BrowserRouter>
      <GlobalMerchantProvider>
        <Routes>
          {walletEmail !== null && walletEmail !== "" ? (
            <>
              {walletEmail === merchantAddress ? (
                <>
                  <Route
                    path="/approve-user"
                    element={<ApproveLayout setWalletEmail={setWalletEmail} />}
                  />
                  <Route
                    path="*"
                    element={<Navigate to="/approve-user" replace />}
                  />
                </>
              ) : (
                <>
                  <Route path="/auth/*" element={<AuthLayout />} />
                  <Route
                    path="/admin/*"
                    element={<Admin setWalletEmail={setWalletEmail} />}
                  />
                  <Route path="/rtl/*" element={<RTLLayout />} />
                  <Route
                    path="*"
                    element={<Navigate to="/admin/dashboard" replace />}
                  />
                </>
              )}
            </>
          ) : (
            <>
              <Route
                path="/sign"
                element={<SignContainer setWalletEmail={setWalletEmail} />}
              />
              <Route path="*" element={<Navigate to="/sign" replace />} />
            </>
          )}
        </Routes>
      </GlobalMerchantProvider>
    </BrowserRouter>
  );
}

export default App;
