import React from "react";
import { NavLink, useLocation } from "react-router-dom";
import PropTypes from "prop-types";
import PerfectScrollbar from "perfect-scrollbar";
import { Nav, Collapse } from "reactstrap";
import { useGlobalMerchant } from "utils/helper";
import imgHolder from "assets/img/img__holder.svg";
import { getSpecificMerchantSetting } from "apiServices/setting";
import { baseImageURL } from "utils/env";
import ButtonComponent from "components/FormComponents/ButtonComponent";
import { faPowerOff } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Spin, Tooltip } from "antd";
import { LoadingOutlined } from "@ant-design/icons";

var ps;

const Sidebar = (props) => {
  const [state, setState] = React.useState({});
  const [showLoading, setShowLoading] = React.useState(false);
  const [merchantContent, setmerchantContent] = React.useState({});
  const sidebarRef = React.useRef(null);
  const location = useLocation();
  const { globalMerchantId, setHaveRelaodGlobal, haveReloadGlobal } =
    useGlobalMerchant();

  const onLogout = () => {
    localStorage.clear();
    props.setWalletEmail("");
  };

  const onGetUsersData = (getKey) => {
    try {
      setShowLoading(true);
      getSpecificMerchantSetting(getKey).then((response) => {
        const responseData = response.data || response;
        if (responseData !== null && responseData.success !== false) {
          setmerchantContent(responseData);
          setHaveRelaodGlobal(false);
          setShowLoading(false);
        }
      });
    } catch (error) {}
  };

  React.useEffect(() => {
    if (globalMerchantId !== null && globalMerchantId !== undefined) {
      onGetUsersData(globalMerchantId);
    }
  }, [globalMerchantId]);
  React.useEffect(() => {
    setState(getCollapseStates(props.routes));
  }, []);
  React.useEffect(() => {
    if (
      haveReloadGlobal &&
      globalMerchantId !== null &&
      globalMerchantId !== undefined
    ) {
      onGetUsersData(globalMerchantId);
    }
  }, [haveReloadGlobal]);
  React.useEffect(() => {
    // if you are using a Windows Machine, the scrollbars will have a Mac look
    if (navigator.platform.indexOf("Win") > -1) {
      ps = new PerfectScrollbar(sidebarRef.current, {
        suppressScrollX: true,
        suppressScrollY: false,
      });
    }
    return function cleanup() {
      // we need to destroy the false scrollbar when we navigate
      // to a page that doesn't have this component rendered
      if (navigator.platform.indexOf("Win") > -1) {
        ps.destroy();
      }
    };
  });
  // this creates the intial state of this component based on the collapse routes
  // that it gets through props.routes
  const getCollapseStates = (routes) => {
    let initialState = {};
    routes.map((prop, key) => {
      if (prop.collapse) {
        initialState = {
          [prop.state]: getCollapseInitialState(prop.views),
          ...getCollapseStates(prop.views),
          ...initialState,
        };
      }
      return null;
    });
    return initialState;
  };
  // this verifies if any of the collapses should be default opened on a rerender of this component
  // for example, on the refresh of the page,
  // while on the src/views/forms/RegularForms.js - route /admin/regular-forms
  const getCollapseInitialState = (routes) => {
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse && getCollapseInitialState(routes[i].views)) {
        return true;
      } else if (window.location.href.indexOf(routes[i].path) !== -1) {
        return true;
      }
    }
    return false;
  };
  // this function creates the links and collapses that appear in the sidebar (left menu)
  const createLinks = (routes) => {
    const { rtlActive } = props;
    return routes.map((prop, key) => {
      if (prop.redirect) {
        return null;
      }
      if (prop.collapse) {
        var st = {};
        st[prop["state"]] = !state[prop.state];
        return (
          <li
            className={getCollapseInitialState(prop.views) ? "active" : ""}
            key={key}
          >
            <a
              href="#pablo"
              data-toggle="collapse"
              aria-expanded={state[prop.state]}
              onClick={(e) => {
                e.preventDefault();
                setState({ ...state, ...st });
              }}
            >
              {prop.icon !== undefined ? (
                <>
                  <i className={prop.icon} />
                  <p>
                    {rtlActive ? prop.rtlName : prop.name}
                    <b className="caret" />
                  </p>
                </>
              ) : (
                <>
                  <span className="sidebar-mini-icon">
                    {rtlActive ? prop.rtlMini : prop.mini}
                  </span>
                  <span className="sidebar-normal">
                    {rtlActive ? prop.rtlName : prop.name}
                    <b className="caret" />
                  </span>
                </>
              )}
            </a>
            <Collapse isOpen={state[prop.state]}>
              <ul className="nav">{createLinks(prop.views)}</ul>
            </Collapse>
          </li>
        );
      }
      return (
        <li className={activeRoute(prop.layout + prop.path)} key={key}>
          <NavLink to={prop.layout + prop.path} onClick={props.closeSidebar}>
            {prop.icon !== undefined ? (
              <>
                <i className={prop.icon} />
                <p>{rtlActive ? prop.rtlName : prop.name}</p>
              </>
            ) : (
              <>
                <span className="sidebar-mini-icon">
                  {rtlActive ? prop.rtlMini : prop.mini}
                </span>
                <span className="sidebar-normal">
                  {rtlActive ? prop.rtlName : prop.name}
                </span>
              </>
            )}
          </NavLink>
        </li>
      );
    });
  };
  // verifies if routeName is the one active (in browser input)
  const activeRoute = (routeName) => {
    return location.pathname === routeName ? "active" : "";
  };

  const { activeColor, logo } = props;
  let logoImg = null;
  let logoText = null;
  if (logo !== undefined) {
    if (logo.outterLink !== undefined) {
      logoImg = (
        <a
          href={logo.outterLink}
          className="simple-text logo-mini"
          target="_blank"
          onClick={props.closeSidebar}
        >
          <div className="logo-img">
            <img src={logo.imgSrc} alt="react-logo" />
          </div>
        </a>
      );
      logoText = (
        <a
          href={logo.outterLink}
          className="simple-text logo-normal"
          target="_blank"
          onClick={props.closeSidebar}
        >
          {logo.text}
        </a>
      );
    } else {
      logoImg = (
        <NavLink
          to={logo.innerLink}
          className="simple-text logo-mini"
          onClick={props.closeSidebar}
        >
          <div className="logo-img">
            <img src={logo.imgSrc} alt="react-logo" />
          </div>
        </NavLink>
      );
      logoText = (
        <NavLink
          to={logo.innerLink}
          className="simple-text logo-normal"
          onClick={props.closeSidebar}
        >
          {logo.text}
        </NavLink>
      );
    }
  }
  return (
    <div className="sidebar" data={activeColor}>
      <div className="sidebar-wrapper" ref={sidebarRef}>
        <div className="merchantInfo">
          {showLoading ? (
            <div
              className="d-flex align-items-center justify-content-center w-100"
              style={{ height: "48px" }}
            >
              <Spin
                indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
              />
            </div>
          ) : (
            <>
              {logoImg !== null || logoText !== null ? (
                <>
                  <div className="imImg">
                    <img
                      className="sideMerchantLogo"
                      src={
                        merchantContent.logo_path !== null &&
                        merchantContent.logo_path !== undefined
                          ? `${baseImageURL}${merchantContent.logo_path}`
                          : imgHolder
                      }
                      alt="business logo"
                    />
                  </div>
                  <div className="imText">
                    <h4 className="sideMerchantName mb-0">
                      {merchantContent.business_name}
                    </h4>
                    <span>{merchantContent.merchant_email}</span>
                  </div>
                </>
              ) : null}
            </>
          )}
        </div>
        <Nav>{createLinks(props.routes)}</Nav>
        {/* navBottom */}
        <div className="navBottom">
          <div className="nbAppicn text-center mb-3">
            <a
              href="https://play.google.com/store/apps/details?id=com.pocketpay.wallet&pli=1"
              target="_blank"
            >
              <i className="fab fa-android me-2"></i>
            </a>
            <a
              href="https://apps.apple.com/us/app/pocketpay-solana-wallet/id1602657206"
              target="_blank"
            >
              <i className="fab fa-apple me-2"></i>
            </a>
          </div>
          <div className="nbSignout">
            <Tooltip title="Sign Out" className="cursorPointer">
              <ButtonComponent
                extraClass="svgAnimationY w-100 soBtn"
                btnPrimary
                onClick={() => onLogout()}
              >
                <span className="btnText">Sign Out</span>
                <FontAwesomeIcon className="ml-2" icon={faPowerOff} />
              </ButtonComponent>
            </Tooltip>
          </div>
        </div>
        {/* <div className="sideBottomWrapper">
          <div className="androidWrapper">
            <a
              href="https://play.google.com/store/apps/details?id=com.pocketpay.wallet&pli=1"
              target="_blank"
            >
              <i class="fab fa-android me-2"></i> <span>Android Beta</span>
            </a>
          </div>
          <div className="iosWrapper">
            <a
              href="https://apps.apple.com/us/app/pocketpay-solana-wallet/id1602657206"
              target="_blank"
            >
              <i class="fab fa-apple me-2"></i> <span>IOS Beta</span>
            </a>
          </div>
          <div className="logoutWrapper" onClick={() => onLogout()}>
            <div className="sideLogoutLogo">
              <PoweroffOutlined />
            </div>
            <span className="sideLogoutName">LogOut</span>
          </div>
        </div> */}
      </div>
    </div>
  );
};

Sidebar.propTypes = {
  activeColor: PropTypes.oneOf(["primary", "blue", "green", "orange", "red"]),
  rtlActive: PropTypes.bool,
  routes: PropTypes.array.isRequired,
  logo: PropTypes.oneOfType([
    PropTypes.shape({
      innerLink: PropTypes.string.isRequired,
      imgSrc: PropTypes.string.isRequired,
      text: PropTypes.string.isRequired,
    }),
    PropTypes.shape({
      outterLink: PropTypes.string.isRequired,
      imgSrc: PropTypes.string.isRequired,
      text: PropTypes.string.isRequired,
    }),
  ]),
  // this is used on responsive to close the sidebar on route navigation
  closeSidebar: PropTypes.func,
};

export default Sidebar;
