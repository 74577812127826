import React, { useRef, useState } from "react";
import {
  CardTitle,
  Form,
  Input,
  Label,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
} from "reactstrap";
import ReCAPTCHA from "react-google-recaptcha";
import { ToastContainer } from "react-toastify";
import { LoadingOutlined } from "@ant-design/icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { login } from "apiServices/app";
import { toastMessage, setStorageData, Email } from "utils/helper";
import ButtonComponent from "components/FormComponents/ButtonComponent";
import { Tooltip } from "antd";

const LoginComponent = ({
  setWalletEmail,
  setShowRegister,
  setShowForgetPassword,
}) => {
  const sitekey = "6Ld7f4EpAAAAAPB6TfEpz8d-vhlHa2Z4SUj-fLjH";
  const recaptchaRef = useRef(null);
  const [isClicked, setIsClicked] = React.useState(false);
  const [btnLoader, setBtnLoader] = React.useState(false);
  const [formData, setFormData] = useState({
    password: "",
    email: "",
  });

  const handleRecaptchaChange = () => {
    setIsClicked(true);
  };

  const handleLogin = async () => {
    if (formData.email !== "" && formData.password !== "") {
      if (!isClicked) {
        toastMessage("Please verify the recaptcha");
        return;
      }
      const verifyEmail = Email(formData.email);
      if (verifyEmail) {
        toastMessage("Please fillup email proper");
        return;
      }
      setBtnLoader(true);
      login(formData)
        .then((response) => {
          const responseData = response.data || response;
          if (responseData.success === false) {
            setBtnLoader(false);
            toastMessage("Invalid email or password.");
          } else {
            toastMessage("Signin successfully");
            setBtnLoader(false);
            setStorageData("pocketpay:email", formData.email);
            setStorageData("pocketpay:user", responseData.loginToken);
            setWalletEmail(formData.email);
          }
        })
        .catch((error) => {
          setBtnLoader(false);
          console.log(error);
        });
    } else {
      toastMessage("All fields are required!");
    }
  };
  React.useEffect(() => {
    document.body.classList.toggle("accountPage");
    return function cleanup() {
      document.body.classList.toggle("accountPage");
    };
  });
  return (
    <>
      <ToastContainer />
      <Form>
        <div className="formTitle mb-3 mb-md-5">
          <CardTitle className="mb-2 font-weight-semibold" tag="h2">
            Sign In
          </CardTitle>
          <span>Welcome back! Please enter your details.</span>
        </div>
        <Label>
          Email Address<span className="text-danger ml-1">*</span>
        </Label>
        <InputGroup>
          <InputGroupAddon addonType="prepend">
            <InputGroupText>
              <i className="tim-icons icon-email-85" />
            </InputGroupText>
          </InputGroupAddon>
          <Input
            type="email"
            placeholder="Enter email"
            value={formData.email}
            onChange={(e) =>
              setFormData({ ...formData, email: e.target.value })
            }
          />
        </InputGroup>
        <Label>
          Password<span className="text-danger ml-1">*</span>
        </Label>
        <InputGroup className="mb-2">
          <InputGroupAddon addonType="prepend">
            <InputGroupText>
              <i className="tim-icons icon-lock-circle" />
            </InputGroupText>
          </InputGroupAddon>
          <Input
            type="password"
            placeholder="Enter password"
            value={formData.password}
            onChange={(e) =>
              setFormData({ ...formData, password: e.target.value })
            }
          />
        </InputGroup>
      </Form>
      <div className="text-right">
        <span
          title="Forgot Password"
          className="cursorPointer text-primary linkHover font-weight-semibold"
          onClick={() => setShowForgetPassword(true)}
        >
          Forgot Password
        </span>
      </div>
      <div className="mt-3">
        <ReCAPTCHA
          ref={recaptchaRef}
          sitekey={sitekey}
          onChange={handleRecaptchaChange}
        />
      </div>
      <div className="mt-4 mb-3">
        <Tooltip title="Login" className="cursorPointer">
          <ButtonComponent
            extraClass="svgAnimationYX w-100"
            btnPrimaryFill
            onClick={() => handleLogin()}
          >
            {"Login"}
            {btnLoader ? (
              <LoadingOutlined className="spinLader ml-2" />
            ) : (
              <FontAwesomeIcon
                style={{ width: "16", height: "16" }}
                className="aniArrow ml-2"
                icon={faArrowRight}
              />
            )}
          </ButtonComponent>
        </Tooltip>
      </div>
      <div className="text-center">
        <span className="font-weight-semibold">
          Don't you have an account?
          <span
            title="Sign Up"
            className="cursorPointer text-primary linkHover ml-1"
            onClick={() => setShowRegister(true)}
          >
            Sign Up
          </span>
        </span>
      </div>
    </>
  );
};

export default LoginComponent;
