import React from "react";
import ReactDOM from "react-dom/client";
import "assets/css/nucleo-icons.css";
import "react-notification-alert/dist/animate.css";
import "assets/scss/ppay-pro-react.scss?v=1.2.0";
// import "assets/demo/demo.css";
import "assets/css/common.css";
import { ApolloProvider } from "@apollo/client";
import App from "App";
import { SolanaProivder } from "./provider/solana";
import client from "utils/tensorClient";

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <SolanaProivder>
    <ApolloProvider client={client}>
      <App />
    </ApolloProvider>
  </SolanaProivder>
);
