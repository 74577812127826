import React, { useEffect, useState } from "react";
import { FormGroup, Form, Input } from "reactstrap";
import { Select, Tooltip } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { getCatogery } from "apiServices/category";
import { baseImageURL, baseURL } from "utils/env";
import { toastMessage, useGlobalMerchant, getStorageData } from "utils/helper";
import ButtonComponent from "components/FormComponents/ButtonComponent";
import ImageUploadComponent from "components/FormComponents/ImageUploadComponent";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";

const PFormComponent = ({
  selectedProduct,
  setSelectedProduct,
  setHaveToReloadProductList,
  setShowViewProductList,
}) => {
  const getWalletEmail = getStorageData("pocketpay:email");
  const getUserToken = getStorageData("pocketpay:user");
  const { globalMerchantId } = useGlobalMerchant();
  // const [categoryList, setCategoryList] = useState([]);
  const [file, setFile] = useState(null);
  const [selectedItems, setSelectedItems] = useState([]);
  const [categoryOptionsList, setCategoryOptionsList] = useState([]);
  const [onBtnLoader, setOnBtnLoader] = useState(false);

  const [formData, setFormData] = useState({
    name: "",
    price: "",
    description: "",
    sku: "",
    img: " ",
    merchant_email: getWalletEmail || "",
    merchant_id: globalMerchantId || "",
  });

  const validateForm = () => {
    let formErrors = {};
    if (!formData.name) formErrors.name = "Please enter name.";
    if (!formData.price) formErrors.price = "Please enter a price.";
    // if (!formData.description) formErrors.name = "Please enter description.";
    if (!formData.sku) formErrors.sku = "Please enter a sku.";
    return Object.keys(formErrors).length === 0;
  };

  const handleFileSelected = (newFile) => {
    const isJpgOrPng =
      newFile.type === "image/jpeg" || newFile.type === "image/png";
    if (!isJpgOrPng) {
      toastMessage(
        "You can only upload JPG/PNG files! This file is not consider"
      );
      return;
    } else {
      setFile(newFile);
    }
  };
  const getCategoryNamesRecursively = (categories) => {
    let options = [];
    categories.forEach((category) => {
      if (category.sub_categories && category.sub_categories.length === 0) {
        const option = {
          category_id: category.category_id,
          category_name: category.category_name,
          image_path: category.image_path,
          parent_category_id: category.parent_category_id,
        };
        options.push(option);
      } else if (
        category.sub_categories &&
        category.sub_categories.length > 0
      ) {
        options = options.concat(
          getCategoryNamesRecursively(category.sub_categories)
        );
      }
    });
    return options;
  };

  const manageCatOptionList = async (getData) => {
    const categoryOptions = await getCategoryNamesRecursively(
      getData && getData
    );
    setCategoryOptionsList(categoryOptions);
    if (Object.keys(selectedProduct).length > 0) {
      const categoryNames = selectedProduct.categories.map(
        (category) => category.name
      );
      setSelectedItems(categoryNames);
    }
  };

  const onStoreProduct = async (getSelectedData) => {
    setOnBtnLoader(true);
    const formDataImage = new FormData();
    file && formDataImage.append("image", file);
    formDataImage.append("name", formData.name);
    formDataImage.append("price", formData.price);
    formDataImage.append("sku", formData.sku);
    formDataImage.append("description", "pocketpay.finance");
    formDataImage.append("merchant_email", getWalletEmail);
    formDataImage.append("merchant_id", globalMerchantId);
    formDataImage.append("category", JSON.stringify(getSelectedData));
    try {
      const response = await fetch(`${baseURL}merchant_product`, {
        method: "POST",
        body: formDataImage,
        headers: {
          Authorization: getUserToken,
        },
      });
      const data = await response.json();
      if (data && data.success === true) {
        toastMessage("Successfully Added");
        setShowViewProductList(true);
        setHaveToReloadProductList(true);
      } else {
        setOnBtnLoader(false);
      }
    } catch (error) {
      setOnBtnLoader(false);
      console.error("Error uploading image:", error);
    }
  };

  const onUpdateProduct = async (getSelectedData) => {
    setOnBtnLoader(true);
    const formDataImage = new FormData();
    file && formDataImage.append("image", file);
    formDataImage.append("name", formData.name);
    formDataImage.append("price", formData.price);
    formDataImage.append("sku", formData.sku);
    formDataImage.append("description", "pocketpay.finance");
    formDataImage.append("merchant_email", getWalletEmail);
    formDataImage.append("merchant_id", globalMerchantId);
    formDataImage.append("category", JSON.stringify(getSelectedData));
    try {
      const response = await fetch(
        `${baseURL}update_merchant_product/${selectedProduct.product_id}`,
        {
          method: "PUT",
          body: formDataImage,
          headers: {
            Authorization: getUserToken,
          },
        }
      );
      const data = await response.json();
      if (data && data.success === true) {
        toastMessage("Successfully Updated");
        setShowViewProductList(true);
        setHaveToReloadProductList(true);
      } else {
        setOnBtnLoader(false);
      }
    } catch (error) {
      setOnBtnLoader(false);
      console.error("Error uploading image:", error);
    }
  };

  const handleProduct = async () => {
    if (validateForm()) {
      if (formData.price && formData.price.startsWith("-")) {
        toastMessage("Price should be in an integer");
        return;
      }
      if (globalMerchantId && globalMerchantId !== "") {
        const selectedData =
          categoryOptionsList &&
          categoryOptionsList
            .filter((item) => selectedItems.includes(item.category_name))
            .map((item) => ({
              id: item.category_id,
              name: item.category_name,
            }));
        if (
          getWalletEmail !== null &&
          getUserToken !== null &&
          selectedData.length > 0
        ) {
          if (Object.keys(selectedProduct).length > 0) {
            if (!file && selectedProduct.img === null) {
              toastMessage("Please select an image first.");
              return;
            } else {
              onUpdateProduct(selectedData);
            }
          } else {
            if (!file) {
              toastMessage("Please select an image first.");
              return;
            } else {
              onStoreProduct(selectedData);
            }
          }
        } else {
          toastMessage("Please select a category.");
        }
      }
    } else {
      toastMessage("All fields are required!");
    }
  };

  const onSetFormData = () => {
    setFormData({
      name: selectedProduct.product_name,
      price: selectedProduct.price,
      sku: selectedProduct.sku,
      description: selectedProduct.description,
      img: " ",
      merchant_email: getWalletEmail || "",
      merchant_id: globalMerchantId || "",
    });
  };

  const getCategoryList = () => {
    try {
      getCatogery(globalMerchantId).then((response) => {
        const responseData = response.data || response;
        if (responseData !== null && responseData.success !== false) {
          if (responseData.length === 0) {
            toastMessage("OOPS you dont have any record");
          } else {
            // setCategoryList(responseData);
            if (Object.keys(selectedProduct).length > 0) {
              onSetFormData();
            }
            manageCatOptionList(responseData);
          }
        }
      });
    } catch (error) {}
  };

  useEffect(() => {
    globalMerchantId !== null &&
      globalMerchantId !== undefined &&
      getCategoryList();
  }, []);

  return (
    <div className="content">
      <Form action="#">
        <FormGroup>
          <label>
            Name<span className="text-danger ml-1">*</span>
          </label>
          <Input
            type="text"
            placeholder="Filter Coffee"
            value={formData.name}
            onChange={(e) =>
              setFormData({
                ...formData,
                name: e.target.value,
              })
            }
          />
        </FormGroup>
        <FormGroup>
          <label htmlFor="chain">
            Select Category<span className="text-danger ml-1">*</span>
          </label>
          <Select
            mode="multiple"
            placeholder="Inserted are removed"
            value={selectedItems}
            onChange={setSelectedItems}
            className="w-100"
            options={categoryOptionsList.map((category) => ({
              value: category.category_name,
              label: category.category_name,
            }))}
          />
        </FormGroup>
        <FormGroup>
          <label>
            SKU<span className="text-danger ml-1">*</span>
          </label>
          <Input
            type="text"
            placeholder="KS593528NT"
            value={formData.sku}
            onChange={(e) =>
              setFormData({
                ...formData,
                sku: e.target.value,
              })
            }
          />
        </FormGroup>
        <FormGroup>
          <label>
            Price (USD)<span className="text-danger ml-1">*</span>
          </label>
          <Input
            type="number"
            placeholder="Enter price in USD"
            value={formData.price}
            onChange={(e) =>
              setFormData({ ...formData, price: e.target.value })
            }
          />
        </FormGroup>
        <FormGroup className="mt-3">
          <label>
            Product Image<span className="text-danger ml-1">*</span>
          </label>
          <ImageUploadComponent
            dynamicImage={
              Object.keys(selectedProduct).length > 0
                ? selectedProduct.img
                : null
            }
            dynamicImageText={"product image"}
            addBtnColor="default"
            changeBtnColor="default"
            onFileSelected={handleFileSelected}
          />
        </FormGroup>
        <hr />
      </Form>
      <div className="text-right mt-3">
        <Tooltip
          title={Object.keys(selectedProduct).length > 0 ? "Update" : "Submit"}
          className="cursorPointer"
        >
          <ButtonComponent
            extraClass="svgAnimationYX ml-2"
            btnPrimary
            onClick={() => !onBtnLoader && handleProduct()}
          >
            {Object.keys(selectedProduct).length > 0 ? "Update" : "Submit"}
            {onBtnLoader ? (
              <LoadingOutlined className="spinLader ml-2" />
            ) : (
              <FontAwesomeIcon
                style={{ width: "16", height: "16" }}
                className="aniArrow ml-2"
                icon={faArrowRight}
              />
            )}
          </ButtonComponent>
        </Tooltip>
      </div>
    </div>
  );
};

export default PFormComponent;
