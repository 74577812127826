import React, { useEffect, useState } from "react";
import { Spin, Tooltip } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
} from "reactstrap";
import { baseURL, baseImageURL } from "utils/env";
import { toastMessage, useGlobalMerchant, getStorageData } from "utils/helper";
import { getSpecificMerchantSetting } from "apiServices/setting";
import ButtonComponent from "components/FormComponents/ButtonComponent";
import ImageUploadComponent from "components/FormComponents/ImageUploadComponent";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { Email } from "utils/helper";

const CheckoutComponent = () => {
  const walletEmail = getStorageData("pocketpay:email");
  const getUserToken = getStorageData("pocketpay:user");
  const { globalMerchantId, setHaveRelaodGlobal } = useGlobalMerchant();
  const [file, setFile] = useState(null);
  const [merchantId, setMerchantId] = useState("");
  const [isUpdateProfile, setIsUpdateProfile] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [onBtnLoader, setOnBtnLoader] = useState(false);
  const [formData, setFormData] = useState({
    merchant_name: "",
    verification_status: "Pending",
    business_name: "",
    business_description: "",
    support_email: "",
    logo_path: " ",
    merchant_email: walletEmail || "",
    enable_emails: true,
  });

  const validateForm = () => {
    const emailRegex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;
    if (!formData.merchant_name) toastMessage("Please enter a merchant name");
    else if (!formData.business_name)
      toastMessage("Please enter a business name");
    else if (!formData.business_description)
      toastMessage("Please enter a business description");
    else if (!formData.support_email)
      toastMessage("Please enter a support email");
    else if (!emailRegex.test(formData.support_email))
      toastMessage("Please enter a correct email address");
    else return true;
  };

  const onUpdateUsersData = async () => {
    if (validateForm()) {
      const verifyEmail = Email(formData.support_email);
      if (verifyEmail) {
        toastMessage("Please fillup email proper");
        return;
      }
      if (merchantId !== "") {
        if (!file && formData.logo_path == null) {
          toastMessage("Please select an image first.");
          return;
        }
        if (walletEmail !== null && getUserToken !== null) {
          setOnBtnLoader(true);
          const formDataImage = new FormData();
          file && formDataImage.append("image", file);
          formDataImage.append("merchant_name", formData.merchant_name);
          formDataImage.append(
            "verification_status",
            formData.verification_status
          );
          formDataImage.append("business_name", formData.business_name);
          formDataImage.append("support_email", formData.support_email);
          formDataImage.append(
            "business_description",
            formData.business_description
          );
          formDataImage.append("enable_emails", "1");
          formDataImage.append("merchant_email", walletEmail);
          formDataImage.append("merchant_id", merchantId);

          try {
            const response = await fetch(`${baseURL}merchant_settings`, {
              method: "PUT",
              body: formDataImage,
              headers: {
                Authorization: getUserToken,
              },
            });
            const data = await response.json();
            if (data) {
              setIsUpdateProfile(true);
              setOnBtnLoader(false);
              setHaveRelaodGlobal(true);
              toastMessage("Updated Successfully");
            }
          } catch (error) {
            console.error("Error uploading image:", error);
          }
        }
      }
    } else {
      toastMessage("All fields are required!");
    }
  };

  const onGetUsersData = (getKey) => {
    setShowLoader(true);
    setIsUpdateProfile(false);
    try {
      getSpecificMerchantSetting(getKey).then((response) => {
        const responseData = response.data || response;
        if (responseData !== null && responseData.success !== false) {
          setFormData(responseData);
          setMerchantId(getKey);
          setShowLoader(false);
        }
      });
    } catch (error) {}
  };

  const handleFileSelected = (newFile) => {
    const isJpgOrPng =
      newFile.type === "image/jpeg" || newFile.type === "image/png";
    if (!isJpgOrPng) {
      toastMessage(
        "You can only upload JPG/PNG files! This file is not consider"
      );
      return;
    } else {
      setFile(newFile);
    }
  };

  useEffect(() => {
    if (
      walletEmail !== null &&
      globalMerchantId !== null &&
      globalMerchantId !== undefined
    ) {
      onGetUsersData(globalMerchantId);
    }
  }, [globalMerchantId, isUpdateProfile]);

  return (
    <Card className="mb-0 h-100">
      <CardHeader>
        <CardTitle tag="h4">Checkout Settings</CardTitle>
      </CardHeader>
      {showLoader ? (
        <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />
      ) : (
        <>
          <CardBody>
            <Form action="#">
              <Row gutter={{ xs: 20, sm: 20, md: 20, lg: 20 }}>
                <Col className="gutter-row" lg="6">
                  <FormGroup>
                    <label>
                      Merchant Name<span className="text-danger ml-1">*</span>
                    </label>
                    <Input
                      type="text"
                      value={formData.merchant_name}
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          merchant_name: e.target.value,
                        })
                      }
                    />
                  </FormGroup>
                </Col>
                <Col className="gutter-row" lg="6">
                  <FormGroup>
                    <label>
                      Business Name<span className="text-danger ml-1">*</span>
                    </label>
                    <Input
                      type="text"
                      value={formData.business_name}
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          business_name: e.target.value,
                        })
                      }
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row gutter={{ xs: 20, sm: 20, md: 20, lg: 20 }}>
                <Col className="gutter-row" lg="6">
                  <FormGroup>
                    <label>
                      Business Description
                      <span className="text-danger ml-1">*</span>
                    </label>
                    <Input
                      type="textarea"
                      value={formData.business_description}
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          business_description: e.target.value,
                        })
                      }
                    />
                  </FormGroup>
                </Col>
                <Col className="gutter-row" lg="6">
                  <FormGroup>
                    <label>
                      Support Email<span className="text-danger ml-1">*</span>
                    </label>
                    <Input
                      type="text"
                      value={formData.support_email}
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          support_email: e.target.value,
                        })
                      }
                    />
                  </FormGroup>
                </Col>
              </Row>
              <FormGroup>
                <label>
                  Profile Image<span className="text-danger ml-1">*</span>
                </label>
                <ImageUploadComponent
                  dynamicImage={formData.logo_path}
                  dynamicImageText={"business logo"}
                  addBtnColor="default"
                  changeBtnColor="default"
                  onFileSelected={handleFileSelected}
                />
              </FormGroup>
            </Form>
          </CardBody>
          <CardFooter className="text-right">
            <Tooltip title="Update Settings" className="cursorPointer">
              <ButtonComponent
                extraClass="svgAnimationYX"
                btnPrimary
                onClick={() => !onBtnLoader && onUpdateUsersData()}
              >
                {"Update Settings"}
                {onBtnLoader ? (
                  <LoadingOutlined
                    style={{ width: "16", height: "16" }}
                    className="ml-2"
                  />
                ) : (
                  <FontAwesomeIcon
                    style={{ width: "16", height: "16" }}
                    className="aniArrow ml-2"
                    icon={faArrowRight}
                  />
                )}
              </ButtonComponent>
            </Tooltip>
          </CardFooter>
        </>
      )}
    </Card>
  );
};

export default CheckoutComponent;
