import React from "react";
import ButtonComponent from "components/FormComponents/ButtonComponent";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
import { Tooltip } from "antd";

const DeleteModal = ({ setShowDeleteModal, onConfirmation }) => {
  return (
    <>
      <div className="py-3">
        <div className="d-flex flex-column align-items-center justify-content-center mb-4">
          <FontAwesomeIcon
            style={{ width: "50px", height: "50px" }}
            className="text-danger mb-2"
            icon={faExclamationTriangle}
          />
          <h4 className="m-0">Are you sure want to delete this?</h4>
        </div>
        <div className="d-flex align-items-center justify-content-center">
          <Tooltip title="No, Keep it" className="cursorPointer">
            <ButtonComponent
              btnSecondary
              extraClass="mr-1"
              onClick={() => setShowDeleteModal(false)}
            >
              No, Keep it.
            </ButtonComponent>
          </Tooltip>
          <Tooltip title="Yes, Delete!" className="cursorPointer">
            <ButtonComponent
              btnDanger
              extraClass="ml-1"
              onClick={() => onConfirmation()}
            >
              Yes, Delete!
            </ButtonComponent>
          </Tooltip>
        </div>
      </div>
    </>
  );
};

export default DeleteModal;
