import { Drawer, Table, Tooltip } from "antd";
import React, { useState } from "react";
import { Card, CardBody } from "reactstrap";
import { CopyIcon } from "svgIcon";
import {
  getMerchantOrderProduct,
  getMerchantPaylinkProduct,
} from "apiServices/orders";
import {
  copyContent,
  checkCoin,
  checkAddress,
  shortenAddress,
} from "utils/helper";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCopy, faEye } from "@fortawesome/free-solid-svg-icons";
import CustomDrawerComponent from "components/FormComponents/CustomDrawerComponent";
import OrdersViewModal from "components/FormComponents/ModalComponent/OrdersViewModal";

const OrderTableComponent = ({ orderList }) => {
  const [productContent, setProductContent] = useState([]);
  const [orderContent, setOrderContent] = useState({});
  const [showGenerateModal, setShowGenerateModal] = useState(false);
  const columns = [
    {
      title: "SR No",
      dataIndex: "sr_no",
      key: "sr_no",
    },
    {
      title: "Amount (USD)",
      dataIndex: "amount",
      key: "amount",
    },
    {
      title: "Date Time (UTC)",
      dataIndex: "date_time",
      key: "date_time",
    },
    {
      title: "Sender Address",
      dataIndex: "client_address",
      key: "client_address",
    },
    {
      title: "Receiver Address",
      dataIndex: "merchant_address",
      key: "merchant_address",
    },
    {
      title: "Transaction ID",
      dataIndex: "digest",
      key: "digest",
    },
    {
      title: "Type",
      dataIndex: "order_type",
      key: "order_type",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      align: "center",
    },
    {
      title: "Action",
      key: "operation",
      dataIndex: "operation",
      align: "center",
      width: 100,
    },
  ];

  const onPosDetails = (order) => {
    try {
      getMerchantOrderProduct(order.order_id).then((response) => {
        const responseData = response.data || response;
        setProductContent(responseData);
        setOrderContent(order);
        setShowGenerateModal(true);
      });
    } catch (error) {}
  };

  const onPaylinkDetails = (order) => {
    try {
      getMerchantPaylinkProduct(order.order_id).then((response) => {
        const responseData = response.data || response;
        if (
          responseData &&
          responseData.products &&
          responseData.products.length > 0
        ) {
          setProductContent(responseData.products);
          setOrderContent(order);
          setShowGenerateModal(true);
        } else {
          setOrderContent(order);
          setShowGenerateModal(true);
        }
      });
    } catch (error) {}
  };

  const onViewContent = (getOrderDetails) => {
    if (
      getOrderDetails.order_type === "Paylinks" ||
      getOrderDetails.order_type === "Terminal"
    ) {
      onPaylinkDetails(getOrderDetails);
    } else {
      onPosDetails(getOrderDetails);
    }
  };

  const onDigestClick = async (getCoin, getDigest) => {
    const url = await checkCoin(getCoin, getDigest);
    window.open(url);
  };

  const onAddressCopy = (getCoin, getDigest) => {
    const url = checkAddress(getCoin, getDigest);
    copyContent(url);
  };

  const onDigestCopy = (getCoin, getDigest) => {
    const url = checkCoin(getCoin, getDigest);
    copyContent(url);
  };

  const responseFormate = (data) => {
    return (
      data &&
      data.map((item, index) => {
        return {
          key: index,
          sr_no: index + 1,
          amount: item.amount === null ? "-" : item.amount,
          date_time: item.status === "completed" ? item.date_time : "-",
          client_address:
            item.status === "completed" &&
            item.client_address !== " " &&
            item.client_address !== null ? (
              <div style={{ display: "flex", gap: "10px" }}>
                <span>{shortenAddress(item.client_address)}</span>
                <Tooltip title="Copy" className="cursorPointer">
                  <span
                    onClick={() =>
                      onAddressCopy(item.coin, item.client_address)
                    }
                  >
                    <FontAwesomeIcon
                      style={{ width: "15", height: "15" }}
                      className="text-primary"
                      icon={faCopy}
                    />
                  </span>
                </Tooltip>
              </div>
            ) : (
              "-"
            ),
          merchant_address:
            item.status === "completed" &&
            item.merchant_address !== " " &&
            item.merchant_address !== null ? (
              <div style={{ display: "flex", gap: "10px" }}>
                <span>{shortenAddress(item.merchant_address)}</span>
                <Tooltip title="Copy" className="cursorPointer">
                  <span
                    onClick={() =>
                      onAddressCopy(item.coin, item.merchant_address)
                    }
                  >
                    <FontAwesomeIcon
                      style={{ width: "15", height: "15" }}
                      className="text-primary"
                      icon={faCopy}
                    />
                  </span>
                </Tooltip>
              </div>
            ) : (
              "-"
            ),
          digest:
            item.status === "completed" ? (
              <div style={{ display: "flex", gap: "10px" }}>
                <span onClick={() => onDigestClick(item.coin, item.digest)}>
                  {shortenAddress(item.digest)}
                </span>
                <Tooltip title="Copy" className="cursorPointer">
                  <span onClick={() => onDigestCopy(item.coin, item.digest)}>
                    <FontAwesomeIcon
                      style={{ width: "15", height: "15" }}
                      className="text-primary"
                      icon={faCopy}
                    />
                  </span>
                </Tooltip>
              </div>
            ) : (
              "-"
            ),
          order_type: item.order_type,
          status: <span className="badge badge-success">{item.status}</span>,
          operation: (
            <Tooltip title="View" className="cursorPointer">
              <span
                onClick={() => {
                  setProductContent([]);
                  onViewContent(item);
                }}
              >
                <FontAwesomeIcon className="text-primary" icon={faEye} />
              </span>
            </Tooltip>
          ),
        };
      })
    );
  };

  return (
    <>
      <Table dataSource={responseFormate(orderList)} columns={columns} />
      <CustomDrawerComponent
        extraClass="orderDetails"
        label={"Order Details"}
        onOpen={showGenerateModal}
        ontoggle={() => setShowGenerateModal(false)}
        drawerSizing={productContent.length > 0 ? 1000 : 500}
      >
        <OrdersViewModal
          productContent={productContent}
          orderContent={orderContent}
        />
      </CustomDrawerComponent>
    </>
  );
};

export default OrderTableComponent;
