import { createUseStyles } from "react-jss";
import LRFbgfrom from "assets/img/ppLRFbg.svg";
import breakpoints from "assets/breakpoints";
export const useStyles = createUseStyles({
  accountWrap: {
    minHeight: "100vh",
    [breakpoints.SCREEN_MDS_MAX]: {
      flexDirection: "column",
    },
    "& .acLeft": {
      padding: "30px",
      backgroundImage: `url(${LRFbgfrom})`,
      backgroundSize: "cover",
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center",
      [breakpoints.SCREEN_XXL_MIN]: {
        maxWidth: "720px",
        flex: "0 0 720px",
      },
      [breakpoints.SCREEN_XXL_MAX]: {
        maxWidth: "680px",
        flex: "0 0 680px",
      },
      [breakpoints.SCREEN_XXLM_MAX]: {
        maxWidth: "620px",
        flex: "0 0 620px",
      },
      [breakpoints.SCREEN_XL_MAX]: {
        maxWidth: "580px",
        flex: "0 0 580px",
      },
      [breakpoints.SCREEN_LG_MAX]: {
        maxWidth: "500px",
        flex: "0 0 500px",
      },
      [breakpoints.SCREEN_MD_MAX]: {
        maxWidth: "400px",
        flex: "0 0 400px",
      },
      [breakpoints.SCREEN_MDS_MIN]: {
        borderTopLeftRadius: "8px",
        borderBottomLeftRadius: "8px",
      },
      [breakpoints.SCREEN_MDS_MAX]: {
        maxWidth: "100%",
        flex: "0 0 100%",
        paddingBottom: "20px",
        borderTopLeftRadius: "8px",
        borderTopRightRadius: "8px",
      },
      "& .acLT": {
        "& .imgLogo": {
          [breakpoints.SCREEN_XXL_MIN]: {
            marginBottom: "80px",
          },
          [breakpoints.SCREEN_XXLM_MIN]: {
            marginBottom: "60px",
          },
          [breakpoints.SCREEN_XXLM_MAX]: {
            marginBottom: "40px",
          },
        },
        "& .LRFimg": {
          [breakpoints.SCREEN_XXL_MIN]: {
            maxWidth: "400px",
            marginBottom: "40px",
          },
          [breakpoints.SCREEN_XXL_MAX]: {
            maxWidth: "380px",
            marginBottom: "380px",
          },
          [breakpoints.SCREEN_XL_MAX]: {
            maxWidth: "340px",
            marginBottom: "30px",
          },
          [breakpoints.SCREEN_LG_MAX]: {
            maxWidth: "280px",
            marginBottom: "25px",
          },
          [breakpoints.SCREEN_MD_MAX]: {
            maxWidth: "240px",
          },
          [breakpoints.SCREEN_MDS_MAX]: {
            maxWidth: "200px",
            marginBottom: "20px",
          },
        },
        "& h3": {
          [breakpoints.SCREEN_XXLM_MIN]: {
            fontSize: "32px",
            lineHeight: "40px",
          },
          [breakpoints.SCREEN_XXLM_MAX]: {
            fontSize: "30px",
            lineHeight: "38px",
          },
          [breakpoints.SCREEN_XL_MAX]: {
            fontSize: "28px",
            lineHeight: "36px",
          },
          [breakpoints.SCREEN_LG_MAX]: {
            fontSize: "26px",
            lineHeight: "34px",
          },
          marginBottom: "20px",
          color: "var(--black)",
          fontWeight: "var(--font-weight-extrabold)",
          "& > br": {
            [breakpoints.SCREEN_LG_MAX]: {
              display: "none",
            },
          },
        },
        "& p": {
          fontSize: "15px",
          lineHeight: "24px",
          color: "var(--gray-700)",
          fontWeight: "var(--font-weight-medium)",
          "& > br": {
            [breakpoints.SCREEN_LG_MAX]: {
              display: "none",
            },
          },
        },
      },
      "& .acLB": {
        "& p": {
          fontSize: "12px",
          color: "var(--gray-700)",
          fontWeight: "var(--font-weight-medium)",
        },
      },
    },
    "& .acRight": {
      flex: "1",
      borderStyle: "solid",
      borderColor: "#f2f4f7",
      [breakpoints.SCREEN_MDS_MIN]: {
        borderWidth: "1px 1px 1px 0",
        borderTopRightRadius: "8px",
        borderBottomRightRadius: "8px",
      },
      [breakpoints.SCREEN_MDS_MAX]: {
        borderWidth: "0 1px 1px 1px",
        borderBottomLeftRadius: "8px",
        borderBottomRightRadius: "8px",
      },
      "& .acRBox": {
        [breakpoints.SCREEN_MDS_MIN]: {
          maxWidth: "400px",
          padding: "5rem 30px 2rem 30px",
        },
        [breakpoints.SCREEN_MDS_MAX]: {
          padding: "2rem 20px 2rem 20px",
        },
      },
    },
  },
});
