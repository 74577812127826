import React from "react";
import { useEffect, useState } from "react";
import { Spin, Switch, Table, Tooltip } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { faCopy, faPlus } from "@fortawesome/free-solid-svg-icons";
import { faEdit } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Card, CardHeader, CardBody, CardTitle } from "reactstrap";
import {
  addressActiveDeactive,
  getSpecificMerchantAddress,
  getBlockchain,
} from "apiServices/setting";
import {
  toastMessage,
  useGlobalMerchant,
  getStorageData,
  ChainSolana,
  ChainSolanaUSDC,
  ChainSOL,
  shortenAddress,
  copyContent,
} from "utils/helper";
import ChainFormComponent from "../ChainFormComponent";
import CustomModalComponent from "components/FormComponents/CustomModalComponent";

const ChainListComponent = () => {
  const walletEmail = getStorageData("pocketpay:email");
  const [chainData, setChainData] = useState([]);
  const [showLoader, setShowLoader] = useState(false);
  const [isEditId, setIsEditId] = useState("");
  const [isEditObject, setIsEditObject] = useState({
    edit_name: "",
    edit_address: "",
  });
  const [filterList, setFilterList] = useState([]);
  const [blockChainList, setBlockChainList] = useState([]);
  const [merchantBlockChainList, setMerchantBlockChainList] = useState([]);
  const { globalMerchantId } = useGlobalMerchant();
  const [subCoinData, setSubCoinData] = useState([]);
  const [showFormModal, setShowFormModal] = useState(false);

  const columns = [
    {
      title: "Name",
      dataIndex: "blockchain_name",
      key: "blockchain_name",
    },
    {
      title: "Address",
      dataIndex: "wallet_address",
      key: "wallet_address",
    },
    {
      title: "Action",
      key: "operation",
      dataIndex: "operation",
      align: "center",
      width: 100,
    },
  ];

  const responseFormate = (data) => {
    return (
      data &&
      data.map((item, index) => {
        return {
          key: index,
          blockchain_name: item.blockchain_name,
          wallet_address: (
            <div>
              <span className="ellipsisChild">
                {shortenAddress(item.wallet_address)}
              </span>
              <Tooltip title="Copy" className="cursorPointer ml-2">
                <span onClick={() => copyContent(item.wallet_address)}>
                  <FontAwesomeIcon className="text-primary" icon={faCopy} />
                </span>
              </Tooltip>
            </div>
          ),
          operation: (
            <div className="d-flex align-items-center justify-content-center">
              <Switch
                checked={item.is_active === 0 ? false : true}
                onChange={() =>
                  onParentToggleApi(
                    item.id,
                    item.blockchain_name,
                    item.is_active
                  )
                }
              />
              <Tooltip title="Edit" className="cursorPointer mx-2">
                <span onClick={() => onEditToggle(item)}>
                  <FontAwesomeIcon className="text-primary" icon={faEdit} />
                </span>
              </Tooltip>
            </div>
          ),
        };
      })
    );
  };

  const expandedRow = () => {
    const subCoinColumns = [
      {
        title: "Coin Name",
        dataIndex: "sub_blockchain_name",
        key: "sub_blockchain_name",
      },
      {
        title: "Address",
        dataIndex: "sub_wallet_address",
        key: "sub_wallet_address",
      },
      {
        title: "Action",
        key: "sub_operation",
        dataIndex: "sub_operation",
        fixed: "right",
        width: 100,
      },
    ];
    const responseSubCoinFormate = (sub_coin) => {
      return (
        sub_coin &&
        sub_coin.map((scoin, index) => {
          return {
            key: index,
            sub_blockchain_name: scoin.blockchain_name,
            sub_wallet_address: (
              <div>
                <span className="ellipsisChild">
                  {shortenAddress(scoin.wallet_address)}
                </span>
                <Tooltip title="Copy" className="cursorPointer ml-2">
                  <span onClick={() => copyContent(scoin.wallet_address)}>
                    <FontAwesomeIcon className="text-primary" icon={faCopy} />
                  </span>
                </Tooltip>
              </div>
            ),
            sub_operation: (
              <div className="d-flex align-items-center">
                <Switch
                  className="mx-1"
                  checked={scoin.is_active === 0 ? false : true}
                  onChange={() =>
                    onSwitchToggle(
                      scoin.id,
                      scoin.blockchain_name,
                      scoin.is_active
                    )
                  }
                />
                <Tooltip title="Edit" className="cursorPointer mx-2">
                  <span onClick={() => onEditToggle(scoin)}>
                    <FontAwesomeIcon className="text-primary" icon={faEdit} />
                  </span>
                </Tooltip>
              </div>
            ),
          };
        })
      );
    };
    return (
      <Table
        columns={subCoinColumns}
        dataSource={responseSubCoinFormate(subCoinData)}
        pagination={false}
      />
    );
  };

  const hereData = (getData) => {
    const solanaBlockchain = getData.find(
      (item) => item.blockchain_name === ChainSolana
    );
    if (solanaBlockchain) {
      const subCoinNames = solanaBlockchain.sub_coin.map((subCoin) => subCoin);
      setSubCoinData(subCoinNames);
    }
  };

  const checkSubCoinStatus = (getDataList, getItemId) => {
    const blockchainItem = getDataList.find(
      (item) => item.blockchain_name === ChainSolana
    );
    if (blockchainItem) {
      const subCoinStatusArray = [];
      blockchainItem.sub_coin.forEach((subCoin) => {
        if (subCoin.is_active === 1) {
          subCoinStatusArray.push("active");
        } else {
          subCoinStatusArray.push("inactive");
        }
      });
      const onlyOneActive =
        subCoinStatusArray.includes("active") &&
        subCoinStatusArray.indexOf("active") ===
          subCoinStatusArray.lastIndexOf("active");
      if (onlyOneActive) {
        return blockchainItem.id;
      } else {
        return null;
      }
    }
  };

  const activateDeactivateSubCoins = async (solanaSubCoinIds, getEnDis) => {
    try {
      for (const id of solanaSubCoinIds) {
        const newObject = {
          merchant_blockchain_id: id,
          status: getEnDis === 0 ? 1 : 0,
        };
        await addressActiveDeactive(newObject);
      }
      toastMessage(getEnDis === 0 ? "Activated" : "Deactivated");
      onGetBlockchainsList(globalMerchantId);
    } catch (error) {
      toastMessage("something went wrong");
    }
  };

  const onParentToggleApi = (getItemId, getItemName, getEnDis) => {
    if (getItemName == ChainSolana) {
      const solanaSubCoinIds = chainData
        .filter((item) => item.blockchain_name === ChainSolana)
        .map((item) => item.sub_coin.map((subItem) => subItem.id))
        .flat();
      const appendedItemId = [getItemId].concat(solanaSubCoinIds); // Concatenating arrays
      activateDeactivateSubCoins(appendedItemId, getEnDis);
    } else {
      const newObject = {
        merchant_blockchain_id: getItemId,
        status: getEnDis === 0 ? 1 : 0,
      };
      try {
        addressActiveDeactive(newObject)
          .then((response) => {
            toastMessage(getEnDis === 0 ? "Activated" : "Deactivated");
            onGetBlockchainsList(globalMerchantId);
          })
          .catch((error) => {});
      } catch (error) {
        toastMessage("something went wrong");
      }
    }
  };

  const onSwitchToggle = (getItemId, getItemName, getEnDis) => {
    if (getEnDis === 1) {
      const getParentId = checkSubCoinStatus(chainData, getItemId);
      if (getParentId === null) {
        onParentToggleApi(getItemId, getItemName, getEnDis);
      } else {
        activateDeactivateSubCoins([getParentId, getItemId], getEnDis);
      }
    } else {
      if (getItemName === ChainSolanaUSDC || getItemName === ChainSOL) {
        for (const item of chainData) {
          if (
            item.sub_coin.some((coin) => coin.blockchain_name === getItemName)
          ) {
            activateDeactivateSubCoins([item.id, getItemId], getEnDis);
          }
        }
      }
    }
  };

  const onGetMerchantSettings = (getMerchantKey, getBlockchainList) => {
    try {
      getSpecificMerchantAddress(getMerchantKey)
        .then((response) => {
          const responseData = response.data || response;
          if (responseData !== null && responseData.success !== false) {
            setMerchantBlockChainList(responseData.reverse());
            const newBlockchainsList = getBlockchainList.map((blockchain) => {
              const newResponseData = responseData.find(
                (responseDataItem) =>
                  responseDataItem.blockchain_name ===
                  blockchain.blockchain_name
              );

              const walletId = newResponseData ? newResponseData.id : "";
              const walletAddress = newResponseData
                ? newResponseData.wallet_address
                : "";
              const getActiveDeactive = newResponseData
                ? newResponseData.is_active
                : "";

              const subCoins = blockchain.sub_coin.map((subCoin) => {
                const subNewResponseData = responseData.map((item) => {
                  const subCoinItem = item.sub_coin.find(
                    (data) => data.blockchain_name === subCoin.blockchain_name
                  );
                  return subCoinItem ? subCoinItem : null;
                });
                const nullSubCoinItems = subNewResponseData.filter(
                  (item) => item !== null
                );

                if (nullSubCoinItems.length > 0) {
                  const subWalletId = nullSubCoinItems[0].id;
                  const subWalletAddress = nullSubCoinItems[0].wallet_address;
                  const subGetActiveDeactive = nullSubCoinItems[0].is_active;

                  return {
                    id: subWalletId,
                    blockchain_id: subCoin.id,
                    blockchain_name: subCoin.blockchain_name,
                    is_active: subGetActiveDeactive,
                    wallet_address: subWalletAddress,
                  };
                } else {
                  // Handle the case when nullSubCoinItems is empty (no match found)
                  return {
                    id: nullSubCoinItems ? subCoin.id : "",
                    blockchain_id: subCoin.id,
                    blockchain_name: subCoin.blockchain_name,
                    is_active: nullSubCoinItems ? subCoin.is_active : "",
                    wallet_address: nullSubCoinItems
                      ? subCoin.wallet_address
                      : "",
                  };
                }
              });

              return {
                id: walletId,
                blockchain_id: blockchain.id,
                blockchain_name: blockchain.blockchain_name,
                is_active: getActiveDeactive,
                wallet_address: walletAddress,
                sub_coin: subCoins,
              };
            });
            if (newBlockchainsList && newBlockchainsList.length > 0) {
              setShowLoader(false);
              setChainData(newBlockchainsList);
              hereData(newBlockchainsList);
            } else {
              setShowLoader(false);
              toastMessage("Dont have any record");
            }
          } else {
            const newBlockchainsList = getBlockchainList.map((blockchain) => {
              return {
                id: 0,
                blockchain_id: blockchain.id,
                blockchain_name: blockchain.blockchain_name,
                is_active: blockchain.is_active,
                wallet_address: "",
              };
            });
            setChainData(newBlockchainsList);
            setShowLoader(false);
          }
        })
        .catch((error) => {});
    } catch (error) {}
  };

  const onGetBlockchainsList = (getMerchantId) => {
    setShowLoader(true);
    try {
      getBlockchain()
        .then((response) => {
          const getBlockchainList = response.data || response;
          setBlockChainList(getBlockchainList);
          onGetMerchantSettings(getMerchantId, getBlockchainList);
        })
        .catch((error) => {
          setShowLoader(false);
        });
    } catch (error) {
      setShowLoader(false);
    }
  };

  const onCompleteFunction = () => {
    onGetBlockchainsList(globalMerchantId);
  };

  const onAddToggle = () => {
    const isMerchantBlockChainListNotEmpty = merchantBlockChainList.length > 0;
    const newFilterList = isMerchantBlockChainListNotEmpty
      ? chainData
          .filter(
            (entry) =>
              !new Set(
                merchantBlockChainList.map((entry) => entry.blockchain_name)
              ).has(entry.blockchain_name)
          )
          .map((entry) => ({ blockchain_name: entry.blockchain_name }))
      : chainData;
    if (newFilterList && newFilterList.length > 0) {
      setFilterList(newFilterList);
      setIsEditId("");
      setIsEditObject({});
      setShowFormModal(true);
    } else {
      toastMessage("Your all chains are enabled");
    }
  };

  const onEditToggle = (getData) => {
    setFilterList(chainData);
    setIsEditId(getData.blockchain_id);
    setIsEditObject({
      edit_name: getData.blockchain_name,
      edit_address: getData.wallet_address,
    });
    setShowFormModal(true);
  };

  useEffect(() => {
    if (
      walletEmail !== null &&
      globalMerchantId !== null &&
      globalMerchantId !== undefined
    ) {
      onGetBlockchainsList(globalMerchantId);
    }
  }, [globalMerchantId]);

  return (
    <Card className="mb-0">
      <CardHeader className="d-flex align-items-center justify-content-between">
        <CardTitle tag="h4">
          Blockchain / Wallet Address Configuration
        </CardTitle>
        <Tooltip title="Add Address" className="cursorPointer">
          <span
            style={{ width: "40px", height: "40px" }}
            className="d-flex align-items-center justify-content-center rounded rounded-pill bg-primary text-white"
            onClick={() => onAddToggle()}
          >
            <FontAwesomeIcon
              className="text-white"
              style={{ width: "16", height: "16" }}
              icon={faPlus}
            />
          </span>
        </Tooltip>
      </CardHeader>
      {showLoader ? (
        <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />
      ) : (
        <CardBody>
          <Table
            scroll={{ y: 295 }}
            responsive={true}
            columns={columns}
            dataSource={responseFormate(merchantBlockChainList)}
            expandable={{
              expandedRowRender: expandedRow,
              rowExpandable: (record) => record.blockchain_name === "Solana",
            }}
            defaultExpandAllRows={true}
            pagination={false}
          />
        </CardBody>
      )}
      <CustomModalComponent
        isOpen={showFormModal}
        toggle={() => setShowFormModal(false)}
        isFormModal={true}
        modalTitle={isEditId ? "Edit Chain" : "Add Chain"}
      >
        {filterList && filterList.length > 0 ? (
          <ChainFormComponent
            chainData={filterList}
            blockChainList={blockChainList}
            merchantBlockChainList={merchantBlockChainList}
            setShowFormModal={setShowFormModal}
            onComplete={onCompleteFunction}
            formFormat={isEditId !== "" ? "edit" : "add"}
            isEditId={isEditId}
            isEditObject={isEditObject}
          />
        ) : (
          <div>OOPS! Something went wrong</div>
        )}
      </CustomModalComponent>
    </Card>
  );
};

export default ChainListComponent;
