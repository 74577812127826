import { UpdateEndTime } from "utils/URLs";
import {
  MerchantAddress,
  MerchantSetting,
  GenerateKey,
  BlockChain,
  MerchantAddressDelete,
  MerchantAddressEnDis,
} from "utils/URLs";
import { get, getWithoutHeader, post, put } from "utils/v2/api";

export const getBlockchain = () => getWithoutHeader(`${BlockChain}`);

export const deleteMerchantAddress = (data) =>
  post(`${MerchantAddressDelete}`, data);

export const createMerchantAddress = (data) =>
  post(`${MerchantAddress}`, data);

export const getMerchantSetting = () => get(`${MerchantSetting}`);

export const getSpecificMerchantSetting = (data) =>
  get(`${MerchantSetting}/${data}`);

export const getSpecificMerchantAddress = (data) =>
  get(`${MerchantAddress}/${data}`);

export const createMerchantSetting = (data) =>
  post(`${MerchantSetting}`, data);

export const putMerchantAddress = (data) =>
  put(`${MerchantSetting}`, data);

export const getGenerateKey = () => get(`${GenerateKey}`);

export const postGenerateKey = () => post(`${GenerateKey}`);

export const addressActiveDeactive = (data) =>
  post(`${MerchantAddressEnDis}`, data);

export const updateMerchantAddress = (data) =>
  put(`${MerchantAddress}`, data);

export const updateEndTimeStatus = (data) =>
  put(`${UpdateEndTime}`, data);
