import { removeProduct } from "apiServices/product";
import { getProduct } from "apiServices/product";
import React, { useEffect, useState } from "react";
import { Spin, Table, Tooltip } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { Card, CardBody, Modal, ModalBody } from "reactstrap";
import MonoImage from "assets/img/mono.svg";
import { baseImageURL } from "utils/env";
import { toastMessage, useGlobalMerchant } from "utils/helper";
import DeleteModal from "components/FormComponents/ModalComponent/DeleteModal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import CustomModalComponent from "components/FormComponents/CustomModalComponent";

const ProductTables = ({
  setSelectedProduct,
  haveToReloadProductList,
  setShowViewProductList,
  setHaveToReloadProductList,
}) => {
  const { globalMerchantId } = useGlobalMerchant();
  const [productList, setProductList] = useState([]);
  const [showLoader, setShowLoader] = useState(false);
  const [deleteOrderId, setDeleteOrderId] = useState("");
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const columns = [
    {
      title: "SR No",
      dataIndex: "sr_no",
      key: "sr_no",
    },
    {
      title: "Image",
      dataIndex: "img",
      key: "img",
    },
    {
      title: "Name",
      dataIndex: "product_name",
      key: "product_name",
    },
    {
      title: "Price (USD)",
      dataIndex: "price",
      key: "price",
    },
    {
      title: "SKU",
      dataIndex: "sku",
      key: "sku",
    },
    {
      title: "Action",
      key: "operation",
      dataIndex: "operation",
      align: "cneter",
      width: 100,
    },
  ];

  const responseFormate = (data) => {
    return (
      data &&
      data.map((item, index) => {
        return {
          key: index,
          sr_no: index + 1,
          img: (
            <img
              className="rounded object-cover"
              width={40}
              height={40}
              alt="Product Image"
              src={
                item.img !== null && item.img !== undefined
                  ? `${baseImageURL}${item.img}`
                  : MonoImage
              }
            />
          ),
          product_name: item.product_name,
          price: item.price,
          sku: item.sku,
          operation: (
            <>
              <div className="d-flex align-items-center justify-content-center">
                <Tooltip title="Edit" className="cursorPointer mx-2">
                  <span
                    onClick={() => {
                      setSelectedProduct(item);
                      setShowViewProductList(false);
                    }}
                  >
                    <FontAwesomeIcon className="text-primary" icon={faEdit} />
                  </span>
                </Tooltip>
                <Tooltip title="Delete" className="cursorPointer mx-2">
                  <span
                    onClick={() => {
                      setDeleteOrderId(item.product_id);
                      setShowDeleteModal(true);
                    }}
                  >
                    <FontAwesomeIcon
                      className="text-danger"
                      icon={faTrashAlt}
                    />
                  </span>
                </Tooltip>
              </div>
            </>
          ),
        };
      })
    );
  };

  const fetchProducts = (globalMerchantId) => {
    setShowLoader(true);
    try {
      getProduct(globalMerchantId).then((response) => {
        const responseData = response.data || response;
        if (responseData !== null && responseData.status !== false) {
          if (responseData.length !== 0) {
            setShowLoader(false);
            setProductList(responseData.reverse());
            setHaveToReloadProductList(false);
          }
        } else {
          setShowLoader(false);
          setProductList([]);
        }
      });
    } catch (error) {}
  };

  const onConfirmation = () => {
    if (deleteOrderId !== "") {
      onRemoveClick(deleteOrderId);
    } else {
      toastMessage("Something went wrong");
    }
  };

  const onRemoveClick = (getProductId) => {
    removeProduct(getProductId)
      .then((response) => {
        const responseData = response.data || response;
        if (responseData) {
          toastMessage("Deleted Successfully!");
          setShowDeleteModal(false);
          fetchProducts(globalMerchantId);
        }
      })
      .catch((error) => {});
  };

  useEffect(() => {
    if (globalMerchantId !== null && haveToReloadProductList) {
      fetchProducts(globalMerchantId);
    }
  }, [haveToReloadProductList]);

  useEffect(() => {
    globalMerchantId !== null && fetchProducts(globalMerchantId);
  }, [globalMerchantId]);

  return (
    <>
      {showLoader ? (
        <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />
      ) : (
        <Table dataSource={responseFormate(productList)} columns={columns} />
      )}
      <CustomModalComponent
        isOpen={showDeleteModal}
        toggle={() => setShowDeleteModal(false)}
        isFormModal={false}
      >
        <DeleteModal
          setShowDeleteModal={setShowDeleteModal}
          onConfirmation={onConfirmation}
        />
      </CustomModalComponent>
    </>
  );
};

export default ProductTables;
