import React, { useEffect, useState } from "react";
import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  FormGroup,
  Form,
  Input,
} from "reactstrap";
import { Spin, Tooltip } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight, faCopy } from "@fortawesome/free-solid-svg-icons";
import {
  useGlobalMerchant,
  getStorageData,
  toastMessage,
  copyContent,
} from "utils/helper";
import { baseImageURL, baseURL, POS_URL } from "utils/env";
import { getSpecificMerchantSetting } from "apiServices/setting";
import ButtonComponent from "components/FormComponents/ButtonComponent";
import ImageUploadComponent from "components/FormComponents/ImageUploadComponent";

const PMerchantComponent = () => {
  const walletEmail = getStorageData("pocketpay:email");
  const getUserToken = getStorageData("pocketpay:user");
  const { globalMerchantId } = useGlobalMerchant();
  const [file, setFile] = useState(null);
  const [merchantId, setMerchantId] = useState("");
  const [isUpdateProfile, setIsUpdateProfile] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [onBtnLoader, setOnBtnLoader] = useState(false);
  const [formData, setFormData] = useState({
    pos_domain_name: "",
  });

  const onUpdateUsersData = async () => {
    if (merchantId !== "") {
      if (!file && formData.pos_domain_logo == null) {
        toastMessage("Please select an image first.");
        return;
      }
      if (
        walletEmail !== null &&
        getUserToken !== null &&
        formData.pos_domain_name !== ""
      ) {
        setOnBtnLoader(true);
        const formDataImage = new FormData();
        file && formDataImage.append("image", file);
        formDataImage.append("pos_domain_name", formData.pos_domain_name);
        formDataImage.append("merchant_id", merchantId);
        try {
          const response = await fetch(`${baseURL}merchant_settings_pos`, {
            method: "PUT",
            body: formDataImage,
            headers: {
              Authorization: getUserToken,
            },
          });
          const data = await response.json();
          if (data) {
            setIsUpdateProfile(true);
            setOnBtnLoader(false);
            toastMessage("Updated Successfully");
          }
        } catch (error) {
          console.error("Error uploading image:", error);
        }
      } else {
        toastMessage("Please enter a domain name.");
      }
    }
  };

  const onGetUsersData = (getKey) => {
    setShowLoader(true);
    setIsUpdateProfile(false);
    try {
      getSpecificMerchantSetting(getKey).then((response) => {
        const responseData = response.data || response;
        if (responseData !== null && responseData.success !== false) {
          setFormData(responseData);
          setMerchantId(getKey);
          setShowLoader(false);
        }
      });
    } catch (error) {}
  };

  const handleFileSelected = (newFile) => {
    const isJpgOrPng =
      newFile.type === "image/jpeg" || newFile.type === "image/png";
    if (!isJpgOrPng) {
      toastMessage(
        "You can only upload JPG/PNG files! This file is not consider"
      );
      return;
    } else {
      setFile(newFile);
    }
  };

  useEffect(() => {
    if (
      walletEmail !== null &&
      globalMerchantId !== null &&
      globalMerchantId !== undefined
    ) {
      onGetUsersData(globalMerchantId);
    }
  }, [globalMerchantId, isUpdateProfile]);

  return (
    <Card className="mb-0 h-100">
      <CardHeader>
        <CardTitle tag="h4">POS Merchant Settings</CardTitle>
      </CardHeader>
      {showLoader ? (
        <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />
      ) : (
        <>
          <CardBody>
            <Form action="#">
              <FormGroup>
                <div>
                  <label className="mr-1">POS URL </label>
                  <span className="font-weight-medium">
                    (Insert your pos url for POS agents)
                  </span>
                  <span className="text-danger ml-1">*</span>
                </div>
              </FormGroup>
              <FormGroup>
                <div className="d-flex flex-wrap align-items-center">
                  <span className="mr-2 mb-0 font-weight-medium">
                    {POS_URL}
                  </span>
                  <Input
                    type="text"
                    style={{ maxWidth: "180px" }}
                    value={formData.pos_domain_name}
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        pos_domain_name: e.target.value,
                      })
                    }
                  />
                  <Tooltip title="Copy URL">
                    <span
                      className="d-inline-flex justify-content-center align-items-center cursorPointer ml-2 bg-primary p-1"
                      style={{
                        width: "30px",
                        height: "30px",
                        borderRadius: "50rem",
                      }}
                      onClick={() =>
                        copyContent(`${POS_URL}${formData.pos_domain_name}`)
                      }
                    >
                      <FontAwesomeIcon
                        className="text-white"
                        style={{ width: "14", height: "14" }}
                        icon={faCopy}
                      />
                    </span>
                  </Tooltip>
                </div>
              </FormGroup>
              <FormGroup className="mt-3">
                <label>
                  POS Image<span className="text-danger ml-1">*</span>
                </label>
                <ImageUploadComponent
                  dynamicImage={formData.pos_domain_logo}
                  dynamicImageText={"pos logo"}
                  addBtnColor="default"
                  changeBtnColor="default"
                  onFileSelected={handleFileSelected}
                />
              </FormGroup>
            </Form>
          </CardBody>
          <CardFooter className="text-right">
            <Tooltip title="Update Settings" className="cursorPointer">
              <ButtonComponent
                extraClass="svgAnimationYX"
                btnPrimary
                onClick={() => !onBtnLoader && onUpdateUsersData()}
              >
                {"Update Settings"}
                {onBtnLoader ? (
                  <LoadingOutlined
                    style={{ width: "16", height: "16" }}
                    className="ml-2"
                  />
                ) : (
                  <FontAwesomeIcon
                    style={{ width: "16", height: "16" }}
                    className="aniArrow ml-2"
                    icon={faArrowRight}
                  />
                )}
              </ButtonComponent>
            </Tooltip>
          </CardFooter>
        </>
      )}
    </Card>
  );
};

export default PMerchantComponent;
