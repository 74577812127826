import React, { useState } from "react";
import { Tooltip } from "reactstrap";
import { CopyIcon } from "svgIcon";
import MonoImage from "assets/img/mono.svg";
import { baseImageURL } from "utils/env";
import {
  copyContent,
  checkCoin,
  checkAddress,
  selectedChainIcon,
  ChainCKTestBTC,
  shortenAddress,
  toastMessage,
} from "utils/helper";
import { Col, Row } from "antd";
import { faCopy } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const OrdersViewModal = ({ orderContent, productContent }) => {
  const [url, setUrl] = useState("");
  const [addressUrl, setAddressUrl] = useState("");

  const onRenderAddress = (getOrders) => {
    const renderAddress = checkCoin(getOrders.coin, getOrders.digest);
    if (renderAddress) {
      setUrl(renderAddress);
    }
  };

  const onRenderSenderAddress = (getOrders) => {
    const renderAddress = checkAddress(
      getOrders.coin,
      getOrders.client_address
    );
    if (renderAddress) {
      setAddressUrl(renderAddress);
    }
  };

  return (
    <>
      <Row
        gutter={{ xs: 20, sm: 20, md: 20, lg: 20 }}
        className="mx-0 h-100 viewList"
      >
        {productContent && productContent.length > 0 && (
          <Col
            className="gutter-row p-4 viewItems"
            span={12}
            xs={{ span: 24 }}
            sm={{ span: 24 }}
            md={{ span: 12 }}
          >
            <div className="viBox">
              <div className="viBHead d-flex align-items-center justify-content-between mb-3">
                <h4 className="m-0">Product Details</h4>
                <p className="m-0">
                  Status:
                  <span className="badge badge-success ml-2">
                    {orderContent.status}
                  </span>
                </p>
              </div>
              <hr />
              <div className="viBBody d-flex flex-column">
                <div
                  className="viprList"
                  style={{
                    maxHeight: "calc(100vh - 191px)",
                    overflow: "hidden auto",
                  }}
                >
                  {productContent.map((product) => (
                    <div
                      className="viprLItems d-flex align-items-center"
                      key={product.id}
                    >
                      <div className="viprLImg">
                        <img
                          alt="Product"
                          width={50}
                          height={50}
                          src={
                            product.image_path !== null || product.img !== null
                              ? orderContent.order_type === "POS"
                                ? `${baseImageURL}${product.image_path}`
                                : ` ${baseImageURL}${product.img}`
                              : MonoImage
                          }
                        />
                      </div>
                      <div className="viprInfo d-flex align-items-center justify-content-between">
                        <div className="viprIL">
                          <h3 className="mb-1">{product.name}</h3>
                          <div className="viprILB d-flex align-items-center">
                            <p>
                              <span>SKU:</span> <span>{product.sku}</span>
                            </p>
                            <p>
                              <span>Qty:</span>{" "}
                              <span>
                                {orderContent &&
                                orderContent.order_type === "Paylinks"
                                  ? 1
                                  : orderContent.qty}
                              </span>
                            </p>
                          </div>
                        </div>
                        <div className="viprIR">
                          <h3 className="m-0">
                            {product.price}
                            <span>USD</span>
                          </h3>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </Col>
        )}
        <Col
          className="gutter-row p-4 viewItems"
          span={productContent && productContent.length > 0 ? 12 : 24}
          md={
            productContent && productContent.length > 0
              ? { span: 12 }
              : { span: 24 }
          }
          xs={{ span: 24 }}
          sm={{ span: 24 }}
        >
          <div className="viBox">
            {Object.keys(orderContent).length > 0 &&
              orderContent.status === "completed" && (
                <>
                  <div className="viBHead d-flex align-items-center justify-content-between mb-3">
                    <h4 className="m-0">Payment Details</h4>
                    {/* <Tooltip title={`${orderContent.coin}`}>
                      <span>{selectedChainIcon(orderContent.coin)}</span>
                    </Tooltip> */}
                    <span className="viBHIcn">
                      {selectedChainIcon(orderContent.coin)}
                    </span>
                  </div>
                  <hr />
                  <div className="viBBody">
                    <div
                      className="paymList"
                      style={{
                        maxHeight: "calc(100vh - 191px)",
                        overflow: "hidden auto",
                      }}
                    >
                      <div className="paymLItems d-flex align-items-center justify-content-between">
                        <div className="paymLL">TimeStamp:</div>
                        <div className="paymLR">
                          <h3 className="m-0">{orderContent.date_time}</h3>
                        </div>
                      </div>
                      <div className="paymLItems d-flex align-items-center justify-content-between">
                        <div className="paymLL">Order ID:</div>
                        <div className="paymLR">
                          <h3 className="m-0">{orderContent.payment_id}</h3>
                        </div>
                      </div>
                      <div className="paymLItems d-flex align-items-center justify-content-between">
                        <div className="paymLL">Amount:</div>
                        <div className="paymLR">
                          <h3 className="m-0">{orderContent.amount} USD</h3>
                        </div>
                      </div>
                      <div className="paymLItems d-flex align-items-center justify-content-between">
                        <div className="paymLL">Paid In:</div>
                        <div className="paymLR">
                          <h3 className="m-0">
                            {orderContent.coin_amount} {orderContent.coin}
                          </h3>
                        </div>
                      </div>
                      <div className="paymLItems d-flex align-items-center justify-content-between">
                        <div className="paymLL">Conversion Rate:</div>
                        <div className="paymLR">
                          <h3 className="m-0">
                            1 {orderContent.coin} ={" "}
                            {(
                              orderContent.amount / orderContent.coin_amount
                            ).toFixed(4)}
                            * USD
                          </h3>
                        </div>
                      </div>
                      <div className="paymLItems d-flex align-items-center justify-content-between">
                        <div className="paymLL">Transaction ID:</div>
                        {orderContent.coin === ChainCKTestBTC ? (
                          <div
                            className="paymLR"
                            onClick={() =>
                              toastMessage(
                                "CKTESTBTC Testnet explorer is not available."
                              )
                            }
                          >
                            <a
                              title="Click"
                              href={url}
                              target="_blank"
                              rel="noreferrer"
                            >
                              {shortenAddress(orderContent.digest)}
                            </a>
                            <Tooltip
                              title="Copy"
                              className="cursorPointer ml-2"
                            >
                              <span>
                                <FontAwesomeIcon
                                  title="Copy"
                                  className="text-primary"
                                  icon={faCopy}
                                />
                              </span>
                            </Tooltip>
                          </div>
                        ) : (
                          <div
                            className="paymLR"
                            onClick={() => onRenderAddress(orderContent)}
                          >
                            <a
                              title="Click"
                              href={url}
                              target="_blank"
                              rel="noreferrer"
                            >
                              {shortenAddress(orderContent.digest)}
                            </a>
                            <span onClick={() => copyContent(url)}>
                              <FontAwesomeIcon
                                title="Copy"
                                className="text-primary cursorPointer ml-2"
                                icon={faCopy}
                              />
                            </span>
                          </div>
                        )}
                      </div>
                      <div className="paymLItems d-flex align-items-center justify-content-between">
                        <div className="paymLL">Sender Address:</div>
                        <div
                          className="paymLR"
                          onClick={() => onRenderSenderAddress(orderContent)}
                        >
                          {orderContent.client_address == null ||
                          orderContent.client_address == " " ? (
                            "-"
                          ) : (
                            <div className="d-flex align-items-center">
                              <a
                                title="Click"
                                href={addressUrl}
                                target="_blank"
                                rel="noreferrer"
                              >
                                {shortenAddress(orderContent.client_address)}
                              </a>
                              <div onClick={() => copyContent(addressUrl)}>
                                <FontAwesomeIcon
                                  title="Copy"
                                  className="text-primary cursorPointer ml-2"
                                  icon={faCopy}
                                />
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="paymLItems d-flex align-items-center justify-content-between">
                        <div className="paymLL">Receiver Address:</div>
                        <div
                          className="paymLR"
                          onClick={() => onRenderSenderAddress(orderContent)}
                        >
                          {orderContent.merchant_address == null ||
                          orderContent.merchant_address == " " ? (
                            "-"
                          ) : (
                            <div className="d-flex align-items-center">
                              <a
                                title="Click"
                                href={addressUrl}
                                target="_blank"
                                rel="noreferrer"
                              >
                                {shortenAddress(orderContent.merchant_address)}
                              </a>
                              <div onClick={() => copyContent(addressUrl)}>
                                <FontAwesomeIcon
                                  title="Copy"
                                  className="text-primary cursorPointer ml-2"
                                  icon={faCopy}
                                />
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              )}
          </div>
        </Col>
      </Row>
    </>
  );
};

export default OrdersViewModal;
