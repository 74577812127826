import React, { useEffect, useState } from "react";
import { LoadingOutlined } from "@ant-design/icons";
import { faCopy } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useGlobalMerchant, copyContent, toastMessage } from "utils/helper";
import { Spin } from "antd";

const FinderCodeComponent = ({ generateKeyData, showLoader }) => {
  return (
    <>
      {showLoader ? (
        <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />
      ) : (
        <>
          {Object.keys(generateKeyData).length !== 0 ? (
            <div style={{ maxHeight: "20.15rem", overflow: "hidden auto" }}>
              <div className="mb-3">
                <p className="mb-0">Public key</p>
                <h4>
                  <strong>{generateKeyData.public_key}</strong>
                  <FontAwesomeIcon
                    title="Copy"
                    className="text-primary cursorPointer ml-2"
                    icon={faCopy}
                    onClick={() => copyContent(generateKeyData.public_key)}
                  />
                </h4>
              </div>
              <div className="mb-3">
                <p className="mb-0">Private Key</p>
                <h4>
                  <strong>{generateKeyData.private_key_encrypted}</strong>
                  <FontAwesomeIcon
                    title="Copy"
                    className="text-primary cursorPointer ml-2"
                    icon={faCopy}
                    onClick={() =>
                      copyContent(generateKeyData.private_key_encrypted)
                    }
                  />
                </h4>
              </div>
            </div>
          ) : (
            <h4 className="title pl-3">There is no data</h4>
          )}
        </>
      )}
    </>
  );
};

export default FinderCodeComponent;
