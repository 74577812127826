import React from "react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import FEmailComponent from "../FEmailComponent";
import FOTPComponent from "../FOTPComponent";

const ForgetPasswordComponent = ({ setShowForgetPassword }) => {
  const [stepnumber, setStepnumber] = React.useState(1);
  const [emailAddress, setEmailAddress] = React.useState("");

  React.useEffect(() => {
    document.body.classList.toggle("accountPage");
    return function cleanup() {
      document.body.classList.toggle("accountPage");
    };
  });

  return (
    <>
      <ToastContainer />
      {stepnumber === 1 && (
        <FEmailComponent
          emailAddress={emailAddress}
          setEmailAddress={setEmailAddress}
          setStepnumber={setStepnumber}
          setShowForgetPassword={setShowForgetPassword}
        />
      )}
      {stepnumber === 2 && (
        <FOTPComponent
          emailAddress={emailAddress}
          setStepnumber={setStepnumber}
        />
      )}
    </>
  );
};

export default ForgetPasswordComponent;
