import { getCatogery } from "apiServices/category";
import React, { useEffect, useState } from "react";
import { AddSmallIcon } from "svgIcon";
import { Menu, Spin, Table, Tooltip } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { useGlobalMerchant, toastMessage } from "utils/helper";
import { deleteCategory } from "apiServices/category";
import { baseImageURL } from "utils/env";
import MonoImage from "assets/img/mono.svg";
import { faEdit, faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CustomModalComponent from "components/FormComponents/CustomModalComponent";
import CMainFormComponent from "../CMainFormComponent";
import DeleteModal from "components/FormComponents/ModalComponent/DeleteModal";
import ButtonComponent from "components/FormComponents/ButtonComponent";

const CTableComponent = ({
  setHaveToReloadCategoryList,
  haveToReloadCategoryList,
}) => {
  const [selectedCategory, setSelectedCategory] = useState({});
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showChildComponent, setShowChildComponent] = useState(false);
  const { globalMerchantId } = useGlobalMerchant();
  const [categoryList, setCategoryList] = useState([]);
  const [deleteOrderId, setDeleteOrderId] = useState("");
  const [showLoader, setShowLoader] = useState(false);
  const [infoDelModal, setInfoDelModal] = useState(false);

  const onRemoveCategory = (getKey) => {
    const newObject = {
      category_id: getKey,
    };
    try {
      deleteCategory(newObject)
        .then((response) => {
          const responseData = response.data || response;
          if (responseData.success === true) {
            toastMessage("Deleted Successfully");
            setHaveToReloadCategoryList(true);
            setShowDeleteModal(false);
            setShowChildComponent(false);
          } else {
            setInfoDelModal(true);
          }
        })
        .catch((error) => {});
    } catch (error) {}
  };

  const onConfirmation = () => {
    if (deleteOrderId !== "") {
      onRemoveCategory(deleteOrderId);
    } else {
      toastMessage("Something went wrong");
    }
  };

  const getCategoryList = () => {
    setShowLoader(true);
    try {
      getCatogery(globalMerchantId).then((response) => {
        const responseData = response.data || response;
        if (responseData !== null && responseData.success !== false) {
          if (responseData.length === 0) {
            setShowLoader(false);
            setCategoryList([]);
          } else {
            setShowLoader(false);
            setShowChildComponent(false);
            setCategoryList(responseData.reverse());
            setHaveToReloadCategoryList(false);
          }
        }
      });
    } catch (error) {}
  };

  const onRemoveClose = () => {
    setShowDeleteModal(false);
    setDeleteOrderId("");
  };

  const columns = [
    {
      title: "SR No",
      dataIndex: "sr_no",
      key: "sr_no",
    },
    {
      title: "Image",
      dataIndex: "image_path",
      key: "image_path",
    },
    {
      title: "Name",
      dataIndex: "category_name",
      key: "category_name",
    },
    {
      title: "Action",
      key: "operation",
      dataIndex: "operation",
      align: "cneter",
      width: 100,
    },
  ];

  const responseFormate = (data) => {
    return (
      data &&
      data.map((item, index) => {
        return {
          key: index,
          sr_no: index + 1,
          image_path: (
            <img
              className="rounded object-cover"
              width={40}
              height={40}
              alt="Product Image"
              src={
                item.image_path !== null && item.image_path !== undefined
                  ? `${baseImageURL}${item.image_path}`
                  : MonoImage
              }
            />
          ),
          category_name: item.category_name,
          operation: (
            <>
              <div className="d-flex align-items-center justify-content-center">
                <Tooltip title="Edit" className="cursorPointer mx-2">
                  <span
                    onClick={() => {
                      setSelectedCategory(item);
                      setShowChildComponent(true);
                    }}
                  >
                    <FontAwesomeIcon className="text-primary" icon={faEdit} />
                  </span>
                </Tooltip>
                <Tooltip title="Delete" className="cursorPointer mx-2">
                  <span
                    onClick={() => {
                      setInfoDelModal(false);
                      setDeleteOrderId(item.category_id);
                      setShowDeleteModal(true);
                    }}
                  >
                    <FontAwesomeIcon
                      className="text-danger"
                      icon={faTrashAlt}
                    />
                  </span>
                </Tooltip>
              </div>
            </>
          ),
        };
      })
    );
  };

  useEffect(() => {
    if (globalMerchantId !== null && haveToReloadCategoryList) {
      getCategoryList();
    }
  }, [haveToReloadCategoryList]);

  useEffect(() => {
    globalMerchantId !== null && getCategoryList();
  }, []);

  return (
    <>
      {showLoader ? (
        <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />
      ) : (
        <>
          <Table dataSource={responseFormate(categoryList)} columns={columns} />
          <CustomModalComponent
            isOpen={showChildComponent}
            toggle={() => setShowChildComponent(false)}
            isFormModal={true}
            modalTitle="Edit Category"
          >
            <CMainFormComponent
              selectedCategory={selectedCategory}
              setHaveToReloadCategoryList={setHaveToReloadCategoryList}
            />
          </CustomModalComponent>
          <CustomModalComponent
            isOpen={showDeleteModal}
            toggle={() => onRemoveClose()}
            isFormModal={false}
          >
            {infoDelModal ? (
              <div className="py-3">
                <div className="d-flex flex-column align-items-center justify-content-center mb-4">
                  <h4 className="m-0">
                    OPPS! Please remove the associated product first.
                  </h4>
                </div>
                <div className="d-flex align-items-center justify-content-center">
                  <Tooltip title="Okay, Got it." className="cursorPointer">
                    <ButtonComponent
                      btnSecondary
                      extraClass="mr-1"
                      onClick={() => onRemoveClose()}
                    >
                      Okay, Got it.
                    </ButtonComponent>
                  </Tooltip>
                </div>
              </div>
            ) : (
              <DeleteModal
                setShowDeleteModal={setShowDeleteModal}
                onConfirmation={onConfirmation}
              />
            )}
          </CustomModalComponent>
        </>
      )}
    </>
  );
};

export default CTableComponent;
