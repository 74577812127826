import React, { useEffect, useRef } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { CardTitle } from "reactstrap";
import { InputOTP } from "antd-input-otp";
import { LoadingOutlined } from "@ant-design/icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { toastMessage } from "utils/helper";
import ButtonComponent from "components/FormComponents/ButtonComponent";
import { Tooltip } from "antd";

const FOTPComponent = ({ emailAddress, setStepnumber }) => {
  const sitekey = "6Ld7f4EpAAAAAPB6TfEpz8d-vhlHa2Z4SUj-fLjH";
  const recaptchaRef = useRef(null);
  const [btnLoader, setBtnLoader] = React.useState(false);
  const [isClicked, setIsClicked] = React.useState(false);
  const [otpArray, setOtpArray] = React.useState([]);
  const [otpValue, setOtpValue] = React.useState("");
  const [seconds, setSeconds] = React.useState(60);
  const [haveToResend, setHaveToResend] = React.useState(false);

  const handleRecaptchaChange = () => {
    setIsClicked(true);
  };

  const onRecaptchaVerification = () => {
    if (otpArray.length !== 6) {
      toastMessage("Please enter otp");
      return;
    }
    if (otpValue !== "000000") {
      toastMessage("OTP is not a valid");
      return;
    }
    if (!isClicked) {
      toastMessage("Please verify the recaptcha");
      return;
    }
    toastMessage("Successfully Performed!");
  };

  const handleFinish = (otp) => {
    const otpString = otp.join("");
    setOtpArray(otp);
    setOtpValue(otpString);
  };

  const managedTimer = () => {
    const timer = setInterval(() => {
      setSeconds((prevSeconds) => {
        if (prevSeconds === 0) {
          clearInterval(timer);
          setHaveToResend(true);
          return 0;
        } else {
          return prevSeconds - 1;
        }
      });
    }, 1000);

    return () => clearInterval(timer);
  };

  const onResendCode = () => {
    if (haveToResend) {
      setSeconds(60);
      managedTimer();
      setHaveToResend(false);
    }
  };

  useEffect(() => {
    managedTimer();
  }, []);

  return (
    <>
      <div className="formTitle mb-3 mb-md-5">
        <CardTitle className="mb-2 font-weight-semibold" tag="h2">
          OTP Verification
        </CardTitle>
        <span>
          We sent a code to
          <strong className="ml-1">{emailAddress}</strong>
        </span>
      </div>
      <InputOTP
        value={otpArray}
        inputType="numeric"
        onChange={handleFinish}
        autoFocus={true}
      />
      <div className="text-center mt-3">
        <span className="font-weight-semibold">
          Resend code in: {seconds} seconds
          <p
            title="Resend Code"
            className={
              haveToResend && "cursorPointer text-primary linkHover ml-1"
            }
            onClick={() => onResendCode()}
          >
            Resend Again?
          </p>
        </span>
      </div>
      <div className="mt-3">
        <ReCAPTCHA
          ref={recaptchaRef}
          sitekey={sitekey}
          onChange={handleRecaptchaChange}
        />
      </div>
      <div className="mt-4 mb-3">
        <Tooltip title="Verify OTP" className="cursorPointer">
          <ButtonComponent
            extraClass="svgAnimationYX w-100"
            btnPrimaryFill
            onClick={() => onRecaptchaVerification()}
          >
            {"Verify OTP"}
            {btnLoader ? (
              <LoadingOutlined className="spinLader ml-2" />
            ) : (
              <FontAwesomeIcon
                style={{ width: "16", height: "16" }}
                className="aniArrow ml-2"
                icon={faArrowRight}
              />
            )}
          </ButtonComponent>
        </Tooltip>
      </div>
      <div className="text-center">
        <span
          title="Back to previous"
          className="cursorPointer text-primary linkHover font-weight-semibold"
          onClick={() => setStepnumber(1)}
        >
          Back to previous
        </span>
      </div>
    </>
  );
};

export default FOTPComponent;
