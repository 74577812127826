import React, { useEffect, useState } from "react";
import { Card, CardHeader, CardBody, CardTitle } from "reactstrap";
import { LoadingOutlined } from "@ant-design/icons";
import { Spin, Tooltip } from "antd";
import { getStorageData } from "utils/helper";
import { getPaylinkAPI } from "apiServices/paylinks";
import OrderTableComponent from "components/Orders/OrderTableComponent";
import "./index.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSyncAlt } from "@fortawesome/free-solid-svg-icons";
const OrdersContainer = () => {
  const [showLoader, setShowLoader] = useState(false);
  const walletEmail = getStorageData("pocketpay:email");
  const [orderList, setOrderList] = useState([]);

  const listofPaylinks = (walletEmail) => {
    setShowLoader(true);
    try {
      getPaylinkAPI().then((response) => {
        const responseData = response.data || response;
        if (responseData !== null && responseData.success !== false) {
          const allOrders = responseData.filter(
            (order) =>
              order.order_type === "Paylinks" ||
              order.order_type === "POS" ||
              order.order_type === "Terminal"
          );
          const manageOrders = allOrders.filter(
            (order) => order.status !== "pending"
          );

          setOrderList(manageOrders.reverse());
          setShowLoader(false);
        } else {
          setShowLoader(false);
        }
      });
    } catch (error) {
      setShowLoader(false);
    }
  };

  useEffect(() => {
    if (walletEmail !== null || walletEmail !== undefined) {
      listofPaylinks(walletEmail);
    }
  }, [walletEmail]);
  return (
    <div className="content">
      {showLoader ? (
        <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />
      ) : (
        <Card>
          <CardHeader className="d-flex justify-content-between align-items-center">
            <CardTitle tag="h4">Orders</CardTitle>
            <Tooltip title="Reload" className="cursorPointer">
              <span
                className="d-inline-flex align-items-center"
                onClick={() => listofPaylinks()}
              >
                <FontAwesomeIcon icon={faSyncAlt} className="mr-2" /> Reload
              </span>
            </Tooltip>
          </CardHeader>
          <CardBody>
            <OrderTableComponent orderList={orderList} />
          </CardBody>
        </Card>
      )}
    </div>
  );
};

export default OrdersContainer;
