import { createUseStyles } from "react-jss";
export const useStyles = createUseStyles({
  customDrawer: {
    "& .ant-drawer-header": {
      "& > .ant-drawer-header-title": {
        "& > .ant-drawer-close": {
          order: "2",
          marginInlineEnd: "0",
          "& > span": {
            "& > svg": {
              width: "18px",
              height: "18px",
            },
          },
        },
        "& > .ant-drawer-title": {
          fontWeight: "var(--font-weight-bold)",
          fontSize: "20px",
        },
      },
    },
    "& .ant-drawer-body": {},
    // orderDetails
    "&.orderDetails": {
      "& .ant-drawer-header": {
        backgroundColor: "var(--primary)",
        "& > .ant-drawer-header-title": {
          "& > .ant-drawer-close": {
            "& > span": {
              "& > svg": {
                "& > path": {
                  fill: "var(--white)",
                },
              },
            },
          },
          "& > .ant-drawer-title": {
            color: "var(--white)",
          },
        },
      },
      "& .ant-drawer-body": {
        padding: "0",
        "& .viewList": {
          "& .viewItems": {
            "&:nth-child(even)": {
              backgroundColor: "#f6f6f6",
            },
            "& .viBox": {
              "& .viBHead": {
                "& h4": {
                  fontWeight: "var(--font-weight-bold)",
                },
                "& .viBHIcn": {
                  width: "32px",
                  height: "32px",
                  display: "inline-flex",
                  background: "var(--white)",
                  alignItems: "center",
                  justifyContent: "center",
                  borderRadius: "50rem",
                  overflow: "hidden",
                  boxShadow: "0 0 6px hsl(0deg 0% 0% / 6%)",
                  "& svg": {
                    width: "20px",
                    height: "20px",
                  },
                },
              },
              "& .viBBody": {
                // viprList
                "& .viprList": {
                  border: "1px solid #ededed",
                  borderRadius: "10px",
                  boxShadow: "0 1px 15px 0 rgba(123, 123, 123, 0.05)",
                  background: "var(--white)",
                  "& .viprLItems": {
                    padding: "20px",
                    "&:not(:last-child)": {
                      borderBottom: "1px solid #ededed",
                    },
                    "& .viprLImg": {
                      width: "50px",
                      height: "50px",
                      "& > img": {
                        width: "100%",
                        height: "100%",
                        objectFit: "cover",
                      },
                    },
                    "& .viprInfo": {
                      flex: "1",
                      paddingLeft: "15px",
                      "& .viprIL": {
                        "& > h3": {
                          fontSize: "17px",
                          fontWeight: "var(--font-weight-semibold)",
                        },
                        "& .viprILB": {
                          "& > p": {
                            fontSize: "13px",
                            marginBottom: "0",
                            "&:not(:last-child)": {
                              marginRight: "10px",
                            },
                            "& > span": {
                              "&:first-child": {
                                opacity: "0.8",
                              },
                              "&:last-child": {
                                fontWeight: "var(--font-weight-semibold)",
                              },
                            },
                          },
                        },
                      },
                      "& .viprIR": {
                        "& > h3": {
                          fontSize: "20px",
                          fontWeight: "var(--font-weight-medium)",
                        },
                      },
                    },
                  },
                },
                // paymList
                "& .paymList": {
                  border: "1px solid #ededed",
                  borderRadius: "10px",
                  boxShadow: "0 1px 15px 0 rgba(123, 123, 123, 0.05)",
                  background: "var(--white)",
                  "& .paymLItems": {
                    padding: "14px 20px",
                    "&:not(:last-child)": {
                      borderBottom: "1px solid #ededed",
                    },
                    "& .paymLL": {
                      fontSize: "15px",
                      fontWeight: "var(--font-weight-regular)",
                      color: "var(--black-states)",
                      opacity: "0.95",
                    },
                    "& .paymLR": {
                      fontSize: "17px",
                      fontWeight: "var(--font-weight-semibold)",
                      "& > h3": {
                        fontSize: "17px",
                        fontWeight: "var(--font-weight-semibold)",
                      },
                    },
                  },
                },
              },
            },
          },
        },
      },
    },
  },
});
