import React, { useEffect, useState } from "react";
import { Card, CardBody, CardHeader, CardTitle } from "reactstrap";
import { faCircleDot, faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Tabs, Tooltip } from "antd";
import { redirectURL } from "utils/env";
import { useGlobalMerchant } from "utils/helper";
import { getSpecificMerchantSetting } from "apiServices/setting";
import PFormComponent from "components/POS/Product/PFormComponent";
import PTableComponent from "components/POS/Product/PTableComponent";
import CMainFormComponent from "components/POS/Category/CMainFormComponent";
import CTableComponent from "components/POS/Category/CTableComponent";
import ButtonComponent from "components/FormComponents/ButtonComponent";
import CustomModalComponent from "components/FormComponents/CustomModalComponent";

const POSComponent = () => {
  const { globalMerchantId } = useGlobalMerchant();
  const [showCategory, setShowCategory] = useState(false);
  const [showProducts, setShowProducts] = useState(true);
  const [merchantData, setMerchantData] = useState({});
  const [selectedProduct, setSelectedProduct] = useState({});
  const [showViewProductList, setShowViewProductList] = useState(true);
  const [haveToReloadCategoryList, setHaveToReloadCategoryList] =
    useState(true);
  const [haveToReloadProductList, setHaveToReloadProductList] = useState(true);
  const [showViewCategoryList, setShowViewCategoryList] = useState(true);

  const onChange = (key) => {
    if (key == 0) {
      setShowProducts(true);
      setShowCategory(false);
    } else if (key == 1) {
      setShowCategory(true);
      setShowProducts(false);
    }
  };

  const items = [
    {
      key: "0",
      label: "Products",
      children: (
        <>
          {showProducts && (
            <>
              <div className="d-flex align-items-center justify-content-end mb-3">
                <Tooltip title="Add Product" className="cursorPointer">
                  <ButtonComponent
                    btnPrimary
                    onClick={() => {
                      setSelectedProduct({});
                      setShowViewProductList(false);
                    }}
                  >
                    <FontAwesomeIcon icon={faPlus} className="mr-2" />
                    Product
                  </ButtonComponent>
                </Tooltip>
              </div>
              <PTableComponent
                setSelectedProduct={setSelectedProduct}
                haveToReloadProductList={haveToReloadProductList}
                setShowViewProductList={setShowViewProductList}
                setHaveToReloadProductList={setHaveToReloadProductList}
              />
              <CustomModalComponent
                isOpen={!showViewProductList}
                toggle={() => {
                  setShowViewProductList(true);
                  setSelectedProduct({});
                }}
                isFormModal={true}
                modalTitle={
                  Object.keys(selectedProduct).length > 0
                    ? "Edit Product"
                    : "Add Product"
                }
              >
                <PFormComponent
                  selectedProduct={selectedProduct}
                  setSelectedProduct={setSelectedProduct}
                  setHaveToReloadProductList={setHaveToReloadProductList}
                  setShowViewProductList={setShowViewProductList}
                />
              </CustomModalComponent>
            </>
          )}
        </>
      ),
    },
    {
      key: "1",
      label: "Category",
      children: (
        <>
          {showCategory && (
            <>
              <div className="d-flex align-items-center justify-content-end mb-3">
                <Tooltip title="Add Category" className="cursorPointer">
                  <ButtonComponent
                    btnPrimary
                    onClick={() => setShowViewCategoryList(false)}
                  >
                    <FontAwesomeIcon icon={faPlus} className="mr-2" />
                    Category
                  </ButtonComponent>
                </Tooltip>
              </div>
              <CTableComponent
                haveToReloadCategoryList={haveToReloadCategoryList}
                setHaveToReloadCategoryList={setHaveToReloadCategoryList}
              />
              <CustomModalComponent
                isOpen={!showViewCategoryList}
                toggle={() => setShowViewCategoryList(true)}
                isFormModal={true}
                modalTitle="Add Category"
              >
                <CMainFormComponent
                  setHaveToReloadCategoryList={setHaveToReloadCategoryList}
                  setShowViewCategoryList={setShowViewCategoryList}
                />
              </CustomModalComponent>
            </>
          )}
        </>
      ),
    },
  ];

  const onGetUsersData = (getKey) => {
    try {
      getSpecificMerchantSetting(getKey).then((response) => {
        const responseData = response.data || response;
        if (responseData !== null && responseData.success !== false) {
          setMerchantData(responseData);
        }
      });
    } catch (error) {}
  };

  useEffect(() => {
    if (globalMerchantId !== null && globalMerchantId !== undefined) {
      onGetUsersData(globalMerchantId);
    }
  }, [globalMerchantId]);

  return (
    <>
      <Card>
        <CardHeader className="d-flex justify-content-between align-items-center">
          <CardTitle tag="h4">POS</CardTitle>
          {/* <Tooltip title="Live Storefront" className="cursorPointer">
            {Object.keys(merchantData).length > 0 &&
              merchantData &&
              merchantData.pos_domain_name && (
                <a
                  href={`${redirectURL}pos/${merchantData.pos_domain_name}`}
                  target="_blank"
                  rel="noreferrer"
                  className="text-muted d-flex align-items-center"
                >
                  <FontAwesomeIcon
                    icon={faCircleDot}
                    className="mr-2 text-danger"
                  />
                  Live Storefront
                </a>
              )}
          </Tooltip> */}
        </CardHeader>
        <CardBody>
          <Tabs defaultActiveKey="0" items={items} onChange={onChange} />
        </CardBody>
      </Card>
    </>
  );
};

export default POSComponent;
