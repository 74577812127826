import React, { useEffect, useState } from "react";
import { FormGroup, Form, Input, Row, Col, Button } from "reactstrap";
import { LoadingOutlined } from "@ant-design/icons";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { baseURL } from "utils/env";
import { toastMessage, getStorageData } from "utils/helper";
import ImageUploadComponent from "components/FormComponents/ImageUploadComponent";
import ButtonComponent from "components/FormComponents/ButtonComponent";
import { Tooltip } from "antd";

const CMainFormComponent = ({
  selectedCategory,
  setShowViewCategoryList,
  setHaveToReloadCategoryList,
}) => {
  const getUserToken = getStorageData("pocketpay:user");
  const [file, setFile] = useState(null);
  const [onBtnLoader, setOnBtnLoader] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
  });

  const handleFileSelected = (newFile) => {
    const isJpgOrPng =
      newFile.type === "image/jpeg" || newFile.type === "image/png";
    if (!isJpgOrPng) {
      toastMessage(
        "You can only upload JPG/PNG files! This file is not consider"
      );
      return;
    } else {
      setFile(newFile);
    }
  };

  const onUpdateCategory = async () => {
    if (!file && selectedCategory.image_path === null) {
      toastMessage("Please select an image first.");
      return;
    }
    setOnBtnLoader(true);
    try {
      const formDataImage = new FormData();
      file && formDataImage.append("image", file);
      formDataImage.append("category_name", formData.name);
      const response = await fetch(
        `${baseURL}update_categories/${selectedCategory.category_id}`,
        {
          method: "PUT",
          body: formDataImage,
          headers: {
            Authorization: getUserToken,
          },
        }
      );
      const data = await response.json();
      if (data) {
        setOnBtnLoader(false);
        toastMessage("Updated successfully");
        setHaveToReloadCategoryList(true);
      }
    } catch (error) {
      setOnBtnLoader(false);
      toastMessage(error);
    }
  };

  const onSaveCategory = async () => {
    if (!file) {
      toastMessage("Please select an image first.");
    } else {
      setOnBtnLoader(true);
      try {
        const formDataImage = new FormData();
        file && formDataImage.append("image", file);
        formDataImage.append("category_name", formData.name);
        formDataImage.append("parent_category_id", "");
        const response = await fetch(`${baseURL}create_single_categories`, {
          method: "POST",
          body: formDataImage,
          headers: {
            Authorization: getUserToken,
          },
        });
        const data = await response.json();
        if (data) {
          setOnBtnLoader(false);
          toastMessage("Inserted successfully");
          setHaveToReloadCategoryList(true);
          setShowViewCategoryList(true);
        }
      } catch (error) {
        setOnBtnLoader(false);
        toastMessage(error);
      }
    }
  };

  const onCreateParentCategory = async () => {
    if (formData.name !== "" && getUserToken !== null) {
      if (selectedCategory && Object.keys(selectedCategory).length > 0) {
        onUpdateCategory();
      } else {
        onSaveCategory();
      }
    } else {
      toastMessage("Please enter category name");
    }
  };

  useEffect(() => {
    if (selectedCategory && Object.keys(selectedCategory).length > 0) {
      setFormData({ ...formData, name: selectedCategory.category_name });
    }
  }, []);

  return (
    <div className="content">
      <Form action="#">
        <FormGroup>
          <label>
            Name<span className="text-danger ml-1">*</span>
          </label>
          <Input
            type="text"
            placeholder="Coffee"
            value={formData.name}
            onChange={(e) =>
              setFormData({
                ...formData,
                name: e.target.value,
              })
            }
          />
        </FormGroup>
        <FormGroup className="mt-3">
          <label>
            Category Image<span className="text-danger ml-1">*</span>
          </label>
          {selectedCategory && Object.keys(selectedCategory).length > 0 ? (
            <ImageUploadComponent
              dynamicImage={
                Object.keys(selectedCategory).length > 0
                  ? selectedCategory.image_path
                  : null
              }
              dynamicImageText={"category image"}
              addBtnColor="default"
              changeBtnColor="default"
              onFileSelected={handleFileSelected}
            />
          ) : (
            <ImageUploadComponent
              dynamicImage={null}
              dynamicImageText={"category image"}
              addBtnColor="default"
              changeBtnColor="default"
              onFileSelected={handleFileSelected}
            />
          )}
        </FormGroup>
        <hr />
      </Form>
      <div className="text-right mt-3">
        <Tooltip title="Submit" className="cursorPointer">
          <ButtonComponent
            extraClass="svgAnimationYX ml-2"
            btnPrimary
            onClick={() => !onBtnLoader && onCreateParentCategory()}
          >
            {"Submit"}
            {onBtnLoader ? (
              <LoadingOutlined className="spinLader ml-2" />
            ) : (
              <FontAwesomeIcon
                style={{ width: "16", height: "16" }}
                className="aniArrow ml-2"
                icon={faArrowRight}
              />
            )}
          </ButtonComponent>
        </Tooltip>
      </div>
    </div>
  );
};

export default CMainFormComponent;
