import React, { useEffect, useState } from "react";
import { Card, CardHeader, CardBody, CardTitle, CardFooter } from "reactstrap";
import { Tooltip } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ButtonComponent from "components/FormComponents/ButtonComponent";
import { getGenerateKey, postGenerateKey } from "apiServices/setting";
import { useGlobalMerchant, copyContent, toastMessage } from "utils/helper";
import FinderCodeComponent from "components/Setting/FinderCodeComponent";

const KeyContainer = () => {
  const { globalMerchantId } = useGlobalMerchant();
  const [generateKeyData, setGenerateKeyData] = useState({});
  const [showLoader, setShowLoader] = useState(false);
  const [onBtnLoader, setOnBtnLoader] = useState(false);

  const onGetGenerateKey = () => {
    setShowLoader(true);
    try {
      getGenerateKey().then((response) => {
        const responseData = response.data || response;
        if (
          responseData &&
          responseData.private_key_encrypted &&
          responseData.public_key
        ) {
          setShowLoader(false);
          setGenerateKeyData(responseData);
        } else {
          setShowLoader(false);
        }
      });
    } catch (error) {
      setShowLoader(false);
    }
  };

  const onPostGenerateKey = () => {
    setOnBtnLoader(true);
    try {
      postGenerateKey().then((response) => {
        const responseData = response.data || response;
        if (responseData.status === true) {
          if (globalMerchantId !== null) {
            toastMessage("Congrats!");
            setOnBtnLoader(false);
            onGetGenerateKey();
          } else {
            setOnBtnLoader(false);
            toastMessage("Create your business profile first");
          }
        } else {
          setOnBtnLoader(false);
        }
      });
    } catch (error) {
      setOnBtnLoader(false);
    }
  };

  useEffect(() => {
    onGetGenerateKey();
  }, []);

  return (
    <div className="content">
      <Card>
        <CardHeader className="d-flex justify-content-between align-items-center">
          <CardTitle tag="h4">My Keys</CardTitle>
          <Tooltip title="Generate Keys" className="cursorPointer">
            <ButtonComponent
              extraClass="svgAnimationYX"
              btnPrimary
              onClick={() => !onBtnLoader && onPostGenerateKey()}
            >
              {"Generate Keys"}
              {onBtnLoader ? (
                <LoadingOutlined
                  style={{ width: "16", height: "16" }}
                  className="ml-2"
                />
              ) : (
                <FontAwesomeIcon
                  style={{ width: "16", height: "16" }}
                  className="aniArrow ml-2"
                  icon={faArrowRight}
                />
              )}
            </ButtonComponent>
          </Tooltip>
        </CardHeader>
        <CardBody>
          <FinderCodeComponent
            generateKeyData={generateKeyData}
            showLoader={showLoader}
          />
        </CardBody>
      </Card>
    </div>
  );
};

export default KeyContainer;
