import { ApolloClient, InMemoryCache, createHttpLink } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";

const httpLink = createHttpLink({
  uri: "https://api.tensor.so/graphql",
});

const authLink = setContext((_, { headers }) => {
  const token = "d868e3a8-70a6-4fba-9fd6-458091349232";
  return {
    headers: {
      ...headers,
      "x-tensor-api-key": token,
    },
  };
});

const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache(),
});

export default client;
