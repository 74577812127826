import { Table } from "antd";
import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import React, { useEffect, useState } from "react";
import { Button, Card, CardHeader, CardBody, CardTitle } from "reactstrap";
import { getVerifyUser } from "apiServices/app";
import { toastMessage } from "utils/helper";
import { postAllowUser } from "apiServices/app";
import { ToastContainer } from "react-toastify";

const ApproveLayout = ({ setWalletEmail }) => {
  const [showLoader, setShowLoader] = useState(false);
  const [showBtnLoader, setShowBtnLoader] = useState(false);
  const [dataList, setDataList] = useState([]);
  const [isEditid, setIsEditId] = useState("");

  const onApprovedData = (getItemId, getItemEmail) => {
    try {
      setShowBtnLoader(true);
      const newObject = {
        id: getItemId,
        email: getItemEmail,
      };
      postAllowUser(newObject).then((response) => {
        const responseData = response.data || response;
        if (responseData !== null && responseData.success !== false) {
          setShowBtnLoader(false);
          setIsEditId("");
          toastMessage("Approved Successfully");
        }
      });
    } catch (error) {}
  };

  const columns = [
    {
      title: "SR No",
      dataIndex: "sr_no",
      key: "sr_no",
    },
    {
      title: "Name",
      dataIndex: "username",
      key: "username",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Verify",
      dataIndex: "is_verify",
      key: "is_verify",
    },
    {
      title: "Date Time (UTC)",
      dataIndex: "created_at",
      key: "created_at",
    },
    {
      title: "Action",
      key: "operation",
      dataIndex: "operation",
      align: "center",
      width: 100,
    },
  ];

  const responseFormate = (data) => {
    return (
      data &&
      data.map((item, index) => {
        return {
          key: index,
          sr_no: index + 1,
          username: item.username,
          email: item.email,
          is_verify: item.is_verify === 0 ? "Pending" : "Verified",
          created_at: item.created_at,
          operation:
            item.is_verify === 0 ? (
              <Button
                onClick={() => {
                  setIsEditId(item.user_id);
                  onApprovedData(item.user_id, item.email);
                }}
              >
                {isEditid === item.user_id
                  ? showBtnLoader
                    ? "Loading..."
                    : "Approved"
                  : "Approved"}
              </Button>
            ) : (
              "-"
            ),
        };
      })
    );
  };

  const onGetVerifyData = () => {
    try {
      setShowLoader(true);
      getVerifyUser().then((response) => {
        const responseData = response.data || response;
        if (responseData !== null && responseData.success !== false) {
          setDataList(responseData);
          setShowLoader(false);
        }
      });
    } catch (error) {}
  };

  useEffect(() => {
    onGetVerifyData();
  }, []);
  return (
    <div className="wrapper">
      <ToastContainer />
      <Card>
        <CardHeader className="d-flex align-items-center justify-content-between">
          <CardTitle tag="h4">Pocketpay Userdata</CardTitle>
          <CardTitle
            tag="h4"
            onClick={() => {
              localStorage.clear();
              setWalletEmail("");
            }}
            style={{ cursor: "pointer" }}
          >
            Logout
          </CardTitle>
        </CardHeader>
        {showLoader ? (
          <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />
        ) : (
          <CardBody>
            <Table columns={columns} dataSource={responseFormate(dataList)} />
          </CardBody>
        )}
      </Card>
    </div>
  );
};

export default ApproveLayout;
