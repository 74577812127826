import { getSpecificMerchantAddress } from "apiServices/setting";
import React, { useEffect, useState } from "react";
import { FormGroup, Form, Input, Label } from "reactstrap";
import { Row, Col, Tooltip, DatePicker, Select } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { getProduct } from "apiServices/product";
import { baseImageURL, baseURL } from "utils/env";
import { getStorageData, toastMessage, useGlobalMerchant } from "utils/helper";
import ButtonComponent from "components/FormComponents/ButtonComponent";
import ImageUploadComponent from "components/FormComponents/ImageUploadComponent";

const LinkFormComponent = ({ setShowPaylinkForm, setHaveToReload }) => {
  const getUserToken = getStorageData("pocketpay:user");
  const { globalMerchantId } = useGlobalMerchant();
  const [selectedItems, setSelectedItems] = useState([]);
  const [selectedProductsItems, setSelectedProductsItems] = useState([]);
  const [isChecked, setIsChecked] = useState(false);
  const [onBtnLoader, setOnBtnLoader] = useState(false);
  const [chainListing, setChainListing] = useState([]);
  const [productList, setProductList] = useState([]);
  const [file, setFile] = useState(null);
  const [errors, setErrors] = useState();
  const [manageDate, setManageDate] = useState();
  const [formData, setFormData] = useState({
    merchant_name: "",
    sender_address: " ",
    description: "",
    url: "",
    blockchain: "SUI",
    status: "pending",
    amount: "",
    coin: " ",
    txn_digest: " ",
    customer_email: " ",
    link_expire_time: "",
  });
  const { Option } = Select;
  const blockchainNames = chainListing.map((item) => item.blockchain_name);
  const filteredChainOptions = blockchainNames.filter(
    (name) => !selectedItems.includes(name)
  );

  const productsNames = productList.map((item) => ({
    img: item.img,
    product_id: item.product_id,
    product_name: item.product_name,
    price: item.price,
    sku: item.sku,
  }));

  const filteredProductsOptions = productsNames.filter(
    (item) => !selectedProductsItems.includes(item.sku)
  );

  const validateForm = () => {
    let formErrors = {};
    if (!formData.merchant_name)
      formErrors.merchant_name = "Please enter merchant name.";
    if (!formData.description)
      formErrors.description = "Please enter a description.";
    if (!formData.amount) formErrors.amount = "Please enter an amount.";
    if (!formData.link_expire_time)
      formErrors.link_expire_time = "Please select expire dare & time.";
    return Object.keys(formErrors).length === 0;
  };

  function handlePaste(event) {
    event.preventDefault(); // Prevent the default paste behavior
  }

  const removeError = ({ target: { name } }) => {
    setErrors({ ...errors, [name]: "" });
  };

  const handleMerchantName = (e) => {
    if (e.target.value.length >= 25) {
      setErrors({
        ...errors,
        [e.target.name]: "Enter name upto 25 characters.",
      });
      return;
    }
    setFormData({
      ...formData,
      merchant_name: e.target.value,
    });
    setErrors({ ...errors, [e.target.name]: "" });
  };

  const handleProductAmount = () => {
    let totalAmount = 0;
    selectedProductsItems.forEach((selectedItem) => {
      const selectedProduct = productList.find(
        (product) => product.sku === selectedItem
      );
      if (selectedProduct) {
        totalAmount += selectedProduct.price;
      }
    });
    totalAmount = parseFloat(totalAmount.toFixed(4));
    setFormData({ ...formData, amount: totalAmount });
  };

  const handleAmount = (e) => {
    const validValue = /^-?\d*\.?\d*$/.test(e.target.value);
    if (e.target.value && e.target.value.startsWith("-")) {
      setErrors({
        ...errors,
        [e.target.name]: "Amount should be in an integer",
      });
      return;
    }
    if (!validValue) {
      setErrors({
        ...errors,
        [e.target.name]: "Amount should be in an integer",
      });
      return;
    }
    setFormData({ ...formData, amount: e.target.value });
    setErrors({ ...errors, [e.target.name]: "" });
  };

  const handleDescription = (e) => {
    if (e.target.value.length >= 250) {
      setErrors({
        ...errors,
        [e.target.name]: "Enter description upto 250 characters.",
      });
      return;
    }
    setFormData({
      ...formData,
      description: e.target.value,
    });
    setErrors({ ...errors, [e.target.name]: "" });
  };

  function onChange(date, dateString) {
    const currentDate = new Date();
    const linkExpireTime = new Date(dateString);
    if (linkExpireTime && currentDate > linkExpireTime) {
      toastMessage("Link expire time should be greater than current time.");
      return;
    } else {
      setManageDate(date);
      setFormData({ ...formData, link_expire_time: dateString });
    }
  }

  const createPaylink = async () => {
    if (validateForm()) {
      const randomKey = Math.floor(100000 + Math.random() * 900000);
      const generateUrl = `${window.location.origin}/pay/id=${randomKey}`;
      const selectedData =
        chainListing &&
        chainListing
          .filter((item) => selectedItems.includes(item.blockchain_name))
          .map((item) => ({
            blockchain_id: item.blockchain_id,
            blockchain_name: item.blockchain_name,
          }));
      const selectedProductData =
        productList &&
        productList
          .filter((item) => selectedProductsItems.includes(item.sku))
          .map((item) => item.product_id);

      if (selectedData && selectedData.length == 0) {
        toastMessage("Please select a blockchains.");
        return;
      }
      if (isChecked && selectedProductData && selectedProductData.length == 0) {
        toastMessage("Please select a products.");
        return;
      }
      if (!file) {
        toastMessage("Please select an image first.");
        return;
      }

      if (globalMerchantId && globalMerchantId !== "") {
        if (getUserToken !== null) {
          setOnBtnLoader(true);
          const formDataImage = new FormData();
          file && formDataImage.append("image", file);
          formDataImage.append("amount", formData.amount.toString());
          formDataImage.append("coin", formData.coin);
          formDataImage.append("sender_address", formData.sender_address);
          formDataImage.append("network", "Testnet");
          formDataImage.append("status", formData.status);
          formDataImage.append("txn_digest", formData.txn_digest);
          formDataImage.append("url", generateUrl);
          formDataImage.append("description", formData.description);
          formDataImage.append("merchant_name", formData.merchant_name);
          formDataImage.append("order_type", "0");
          formDataImage.append("customer_email", formData.customer_email);
          formDataImage.append("blockchain", JSON.stringify(selectedData));
          formDataImage.append("products", JSON.stringify(selectedProductData));
          formDataImage.append("link_expire_time", formData.link_expire_time);
          formDataImage.append("merchant_id", globalMerchantId);
          try {
            const response = await fetch(`${baseURL}create_paylinks`, {
              method: "POST",
              body: formDataImage,
              headers: {
                Authorization: getUserToken,
              },
            });
            const data = await response.json();
            if (data && data.status === true) {
              toastMessage("Created Successully!");
              setShowPaylinkForm(false);
              setHaveToReload(true);
              setOnBtnLoader(false);
            } else {
              setOnBtnLoader(false);
            }
          } catch (error) {
            setOnBtnLoader(false);
            console.error("Error uploading image:", error);
          }
        }
      }
    } else {
      toastMessage("All fields are required!");
    }
  };

  const onFetchProducts = () => {
    try {
      getProduct(globalMerchantId).then((response) => {
        const responseData = response.data || response;
        if (responseData !== null && responseData.status !== false) {
          if (responseData.length !== 0) {
            setProductList(responseData.reverse());
          }
        } else {
          setProductList([]);
        }
      });
    } catch (error) {}
  };

  const onFetchBlockChainName = () => {
    try {
      getSpecificMerchantAddress(globalMerchantId)
        .then((response) => {
          const responseData = response.data || response;
          if (Array.isArray(responseData) && responseData.length > 0) {
            const activeNetworks = responseData.filter(
              (network) => network.is_active === 1
            );
            const transformedData = activeNetworks.map((item) => ({
              blockchain_id: item.blockchain_id,
              blockchain_name: item.blockchain_name,
            }));
            setChainListing(transformedData);
          } else {
            console.log("No data found or response is not an array.");
          }
        })
        .catch((error) => {});
    } catch (error) {}
  };

  const handleFileSelected = (newFile) => {
    const isJpgOrPng =
      newFile.type === "image/jpeg" || newFile.type === "image/png";
    if (!isJpgOrPng) {
      toastMessage(
        "You can only upload JPG/PNG files! This file is not consider"
      );
      return;
    } else {
      setFile(newFile);
    }
  };

  useEffect(() => {
    onFetchBlockChainName();
    onFetchProducts();
  }, []);

  useEffect(() => {
    if (isChecked) {
      selectedProductsItems.length > 0
        ? handleProductAmount()
        : setFormData({ ...formData, amount: "" });
    }
  }, [selectedProductsItems]);

  return (
    <div className="content">
      <Form action="#">
        <Row gutter={{ xs: 20, sm: 20, md: 20, lg: 20 }}>
          <Col
            className="gutter-row"
            span={12}
            xs={{ span: 24 }}
            sm={{ span: 24 }}
            md={{ span: 12 }}
          >
            <FormGroup>
              <label>
                Name<span className="text-danger ml-1">*</span>
              </label>
              <Input
                type="text"
                name="merchant_name"
                placeholder="Enter name"
                value={formData.merchant_name}
                onFocus={removeError}
                onChange={handleMerchantName}
              />
              <p className="text-danger">{errors && errors.merchant_name}</p>
            </FormGroup>
          </Col>
          <Col
            className="gutter-row"
            span={12}
            xs={{ span: 24 }}
            sm={{ span: 24 }}
            md={{ span: 12 }}
          >
            <FormGroup>
              <label>
                Amount (USD)<span className="text-danger ml-1">*</span>
              </label>
              <Input
                placeholder="Enter price in USD"
                name="amount"
                value={formData.amount}
                onPaste={handlePaste}
                onFocus={removeError}
                readOnly={isChecked ? true : false}
                onChange={handleAmount}
              />
              <p className="text-danger">{errors && errors.amount}</p>
            </FormGroup>
          </Col>
          <Col
            className="gutter-row"
            span={12}
            xs={{ span: 24 }}
            sm={{ span: 24 }}
            md={{ span: 12 }}
          >
            <FormGroup>
              <label htmlFor="chain">
                Expire Time<span className="text-danger ml-1">*</span>
              </label>
              <DatePicker
                showTime
                className="w-100"
                format="YYYY-MM-DD HH:mm:ss"
                value={
                  formData.link_expire_time === ""
                    ? formData.link_expire_time
                    : manageDate
                }
                onChange={onChange}
              />
            </FormGroup>
          </Col>
          <Col
            className="gutter-row"
            span={12}
            xs={{ span: 24 }}
            sm={{ span: 24 }}
            md={{ span: 12 }}
          >
            <FormGroup>
              <label htmlFor="chain">
                Select Blockchain<span className="text-danger ml-1">*</span>
              </label>
              <Select
                mode="multiple"
                placeholder="Inserted are removed"
                value={selectedItems}
                onChange={setSelectedItems}
                className="w-100"
                options={filteredChainOptions.map((item) => ({
                  value: item,
                  label: item,
                }))}
              />
            </FormGroup>
          </Col>
          <Col className="gutter-row" span={24}>
            <FormGroup>
              <label>
                Description<span className="text-danger ml-1">*</span>
              </label>
              <Input
                type="textarea"
                name="description"
                placeholder="Enter description"
                value={formData.description}
                onFocus={removeError}
                onChange={handleDescription}
              />
              <p className="text-danger">{errors && errors.description}</p>
            </FormGroup>
          </Col>
          <Col className="gutter-row mb-3" span={24}>
            <FormGroup check className="text-left mt-0">
              <Label check>
                <Input
                  type="checkbox"
                  onClick={() => setIsChecked(!isChecked)}
                />
                <span className="form-check-sign" />
                Want to add products?
              </Label>
            </FormGroup>
            {isChecked && (
              <FormGroup className="mt-2 mb-0">
                <label htmlFor="chain">
                  Select Products<span className="text-danger ml-1">*</span>
                </label>
                <Select
                  mode="multiple"
                  className="w-100"
                  placeholder="Inserted are removed"
                  value={selectedProductsItems}
                  onChange={setSelectedProductsItems}
                >
                  <Option key="header" disabled>
                    <table>
                      <thead>
                        <tr>
                          <th>Image</th>
                          <th>Name</th>
                          <th>SKU</th>
                        </tr>
                      </thead>
                    </table>
                  </Option>
                  {filteredProductsOptions.map((item) => (
                    <Option key={item.product_id} value={item.sku}>
                      <table>
                        <tbody>
                          <tr>
                            <td>
                              <img
                                src={`${baseImageURL}${item.img}`}
                                alt="Product Image"
                                style={{
                                  width: "20px",
                                  height: "20px",
                                }}
                              />
                            </td>
                            <td>{item.product_name}</td>
                            <td>{item.sku}</td>
                          </tr>
                        </tbody>
                      </table>
                    </Option>
                  ))}
                </Select>
              </FormGroup>
            )}
          </Col>
          <Col className="gutter-row" span={24}>
            <label>
              Link Image<span className="text-danger ml-1">*</span>
            </label>
            <FormGroup>
              <ImageUploadComponent
                dynamicImage={null}
                dynamicImageText={"paylink image"}
                addBtnColor="default"
                changeBtnColor="default"
                onFileSelected={handleFileSelected}
              />
            </FormGroup>
          </Col>
        </Row>
        <hr />
      </Form>
      <div className="text-right mt-3">
        <Tooltip title="Submit" className="cursorPointer">
          <ButtonComponent
            extraClass="svgAnimationYX ml-2"
            btnPrimary
            onClick={() => !onBtnLoader && createPaylink()}
          >
            {"Submit"}
            {onBtnLoader ? (
              <LoadingOutlined className="spinLader ml-2" />
            ) : (
              <FontAwesomeIcon
                style={{ width: "16", height: "16" }}
                className="aniArrow ml-2"
                icon={faArrowRight}
              />
            )}
          </ButtonComponent>
        </Tooltip>
      </div>
    </div>
  );
};

export default LinkFormComponent;
