import React, { useState } from "react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import LinkFormComponent from "components/Paylink/LinkFormComponent";
import LinkTableComponent from "components/Paylink/LinkTableComponent";
import CustomModalComponent from "components/FormComponents/CustomModalComponent";
import "./index.css";

const PaylinkContainer = () => {
  const [showPaylinkForm, setShowPaylinkForm] = useState(false);
  const [haveToReload, setHaveToReload] = useState(false);
  return (
    <div className="content">
      <ToastContainer />
      <LinkTableComponent
        setShowPaylinkForm={setShowPaylinkForm}
        haveToReload={haveToReload}
        setHaveToReload={setHaveToReload}
      />
      <CustomModalComponent
        isOpen={showPaylinkForm}
        toggle={() => setShowPaylinkForm(false)}
        isFormModal={true}
        modalTitle="Create Link"
      >
        <LinkFormComponent
          setShowPaylinkForm={setShowPaylinkForm}
          setHaveToReload={setHaveToReload}
        />
      </CustomModalComponent>
    </div>
  );
};

export default PaylinkContainer;
