import React from "react";
import { CardTitle } from "reactstrap";
import { Form, Label, Input, FormGroup } from "reactstrap";
import { LoadingOutlined } from "@ant-design/icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { Email, toastMessage } from "utils/helper";
import ButtonComponent from "components/FormComponents/ButtonComponent";
import { Tooltip } from "antd";

const FEmailComponent = ({
  emailAddress,
  setEmailAddress,
  setStepnumber,
  setShowForgetPassword,
}) => {
  const [btnLoader, setBtnLoader] = React.useState(false);

  const handleOnSubmit = () => {
    if (emailAddress === "") {
      toastMessage("Please enter email address");
      return;
    }
    const verifyEmail = Email(emailAddress);
    if (verifyEmail) {
      toastMessage("Please fillup email proper");
      return;
    }
    setStepnumber(2);
  };

  return (
    <>
      <div className="formTitle mb-3 mb-md-5">
        <CardTitle className="mb-2 font-weight-semibold" tag="h2">
          Forgot Password
        </CardTitle>
        <span>No worries, we'll send you reset instructions.</span>
      </div>
      <Form>
        <FormGroup>
          <Label>
            Email Address<span className="text-danger ml-1">*</span>
          </Label>
          <Input
            type="email"
            placeholder="Enter registered email"
            value={emailAddress}
            onChange={(e) => setEmailAddress(e.target.value)}
          />
        </FormGroup>
      </Form>
      <div className="mt-4 mb-3">
        <Tooltip title="Next" className="cursorPointer">
          <ButtonComponent
            extraClass="svgAnimationYX w-100"
            btnPrimaryFill
            onClick={() => handleOnSubmit()}
          >
            {"Next"}
            {btnLoader ? (
              <LoadingOutlined className="spinLader ml-2" />
            ) : (
              <FontAwesomeIcon
                style={{ width: "16", height: "16" }}
                className="aniArrow ml-2"
                icon={faArrowRight}
              />
            )}
          </ButtonComponent>
        </Tooltip>
      </div>
      <div className="text-center">
        <span
          title="Back to login"
          className="cursorPointer text-primary linkHover font-weight-semibold"
          onClick={() => setShowForgetPassword(false)}
        >
          Back to login
        </span>
      </div>
    </>
  );
};

export default FEmailComponent;
