import { useEffect, useState } from "react";
import { Badge, Drawer, Spin, Table, Tooltip } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Modal,
  ModalBody,
} from "reactstrap";
import MonoImage from "assets/img/mono.svg";
import { redirectURL, baseImageURL } from "utils/env";
import { toastMessage, getStorageData } from "utils/helper";
import { deletePaylinkAPI, getPaylinkAPI } from "apiServices/paylinks";
import { getMerchantPaylinkProduct } from "apiServices/orders";
import ButtonComponent from "components/FormComponents/ButtonComponent";
import DeleteModal from "components/FormComponents/ModalComponent/DeleteModal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowRight,
  faCopy,
  faEye,
  faSyncAlt,
  faTrashAlt,
} from "@fortawesome/free-solid-svg-icons";
import CustomModalComponent from "components/FormComponents/CustomModalComponent";
import CustomDrawerComponent from "components/FormComponents/CustomDrawerComponent";
import OrdersViewModal from "components/FormComponents/ModalComponent/OrdersViewModal";

const LinkTableComponent = ({
  haveToReload,
  setHaveToReload,
  setShowPaylinkForm,
}) => {
  const currentDate = new Date();
  const [productContent, setProductContent] = useState([]);
  const [orderList, setOrderList] = useState([]);
  const [ordersText, setOrdersText] = useState("");
  const [deleteOrderId, setDeleteOrderId] = useState("");
  const [showGenerateModal, setShowGenerateModal] = useState(false);
  const [merchantData, setMerchantData] = useState({});
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showLoader, setShowLoader] = useState(true);

  const onPaylinkDetails = (order) => {
    try {
      getMerchantPaylinkProduct(order.order_id).then((response) => {
        const responseData = response.data || response;
        if (
          responseData &&
          responseData.products &&
          responseData.products.length > 0
        ) {
          setProductContent(responseData.products);
          setMerchantData(order);
          setShowGenerateModal(true);
        } else {
          setMerchantData(order);
          setShowGenerateModal(true);
        }
      });
    } catch (error) {}
  };

  const encodeContent = (pos) => {
    return btoa(pos);
  };

  const copyToClipboard = (getKey) => {
    const encryptData = encodeContent(`paylink=${getKey}`);
    const callUrl = `${redirectURL}checkout/${encryptData}`;
    if (callUrl) {
      navigator.clipboard.writeText(callUrl);
      toastMessage("Copied!");
    }
  };

  const onRemovePaylink = (getKey) => {
    const newObject = {
      paylink_id: getKey,
    };
    try {
      deletePaylinkAPI(newObject)
        .then((response) => {
          const responseData = response.data || response;
          if (responseData) {
            toastMessage("Deleted Successfully");
            setShowDeleteModal(false);
            listofPaylinks();
          }
        })
        .catch((error) => {});
    } catch (error) {}
  };

  const onConfirmation = () => {
    if (deleteOrderId !== "") {
      onRemovePaylink(deleteOrderId);
    } else {
      toastMessage("Something went wrong");
    }
  };

  const listofPaylinks = () => {
    setShowLoader(true);
    try {
      getPaylinkAPI().then((response) => {
        const responseData = response.data || response;
        if (responseData !== null && responseData.success !== false) {
          if (responseData.length === 0) {
            setOrdersText("OOPS you dont have any record");
            setShowLoader(false);
          } else {
            const paylinksData = responseData.filter(
              (item) => item.order_type === "Paylinks"
            );
            if (paylinksData.length > 0) {
              setOrdersText("");
              setOrderList(paylinksData.reverse());
              setHaveToReload(false);
              setShowLoader(false);
            } else {
              setOrdersText("OOPS you dont have any record");
              setShowLoader(false);
            }
          }
        } else {
          setShowLoader(false);
        }
      });
    } catch (error) {}
  };

  const columns = [
    {
      title: "SR No",
      dataIndex: "sr_no",
      key: "sr_no",
    },
    {
      title: "Image",
      dataIndex: "image_path",
      key: "image_path",
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Amount (USD)",
      dataIndex: "amount",
      key: "amount",
    },
    {
      title: "Expire Time (UTC)",
      dataIndex: "link_expire_time",
      key: "link_expire_time",
    },
    {
      title: "Order ID",
      dataIndex: "payment_id",
      key: "payment_id",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      align: "center",
    },
    {
      title: "Action",
      dataIndex: "operation",
      key: "operation",
      align: "center",
      width: 100,
    },
  ];

  const responseFormate = (data) => {
    return (
      data &&
      data.map((item, index) => {
        const linkExpireTime = item.link_expire_time
          ? new Date(item.link_expire_time)
          : null;
        const isExpired = linkExpireTime && linkExpireTime < currentDate;
        return {
          key: index,
          sr_no: index + 1,
          image_path: (
            <img
              className="rounded object-cover"
              width={40}
              height={40}
              alt="Product Image"
              src={
                item.image_path !== null && item.image_path !== undefined
                  ? `${baseImageURL}${item.image_path}`
                  : MonoImage
              }
            />
          ),
          name: item.name === null ? "-" : item.name,
          amount: item.amount === null ? "-" : item.amount,
          link_expire_time:
            item.link_expire_time === null ? "-" : item.link_expire_time,
          payment_id: item.status === "completed" ? item.payment_id : "-",
          status:
            item.status === "completed" ? (
              <span className="badge badge-success">{item.status}</span>
            ) : isExpired ? (
              <span className="badge badge-danger">Expired</span>
            ) : (
              <span className="badge badge-warning">{item.status}</span>
            ),
          operation:
            item.status === "completed" ? (
              <Tooltip title="View" className="cursorPointer">
                <span
                  onClick={() => {
                    setProductContent([]);
                    onPaylinkDetails(item);
                  }}
                >
                  <FontAwesomeIcon className="text-primary" icon={faEye} />
                </span>
              </Tooltip>
            ) : isExpired ? (
              ""
            ) : (
              <>
                <div className="d-flex align-items-center justify-content-center">
                  <Tooltip title="Copy" className="cursorPointer mx-2">
                    <span onClick={() => copyToClipboard(item.order_id)}>
                      <FontAwesomeIcon className="text-primary" icon={faCopy} />
                    </span>
                  </Tooltip>
                  <Tooltip title="Delete" className="cursorPointer mx-2">
                    <span
                      onClick={() => {
                        setDeleteOrderId(item.order_id);
                        setShowDeleteModal(true);
                      }}
                    >
                      <FontAwesomeIcon
                        className="text-danger"
                        icon={faTrashAlt}
                      />
                    </span>
                  </Tooltip>
                </div>
              </>
            ),
        };
      })
    );
  };

  useEffect(() => {
    if (haveToReload) {
      listofPaylinks();
    }
  }, [haveToReload]);

  useEffect(() => {
    const walletEmail = getStorageData("pocketpay:email");
    if (walletEmail !== null && walletEmail !== undefined) {
      listofPaylinks();
    }
  }, []);

  return (
    <Card>
      <CardHeader className="d-flex align-items-center justify-content-between">
        <CardTitle tag="h4">Checkout Payments</CardTitle>
        <div className="d-flex align-items-center">
          <Tooltip title="Reload" className="cursorPointer mr-3">
            <span
              className="d-inline-flex align-items-center"
              onClick={() => listofPaylinks()}
            >
              <FontAwesomeIcon icon={faSyncAlt} className="mr-2" /> Reload
            </span>
          </Tooltip>
          <Tooltip title="Create Link" className="cursorPointer">
            <ButtonComponent
              extraClass="svgAnimationYX"
              btnPrimary
              onClick={() => setShowPaylinkForm(true)}
            >
              Create Link
              <FontAwesomeIcon
                style={{ width: "16", height: "16" }}
                className="aniArrow ml-2"
                icon={faArrowRight}
              />
            </ButtonComponent>
          </Tooltip>
        </div>
      </CardHeader>
      {showLoader ? (
        <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />
      ) : (
        <CardBody>
          <Table dataSource={responseFormate(orderList)} columns={columns} />
        </CardBody>
      )}
      <CustomModalComponent
        isOpen={showDeleteModal}
        toggle={() => setShowDeleteModal(false)}
        isFormModal={false}
      >
        <DeleteModal
          setShowDeleteModal={setShowDeleteModal}
          onConfirmation={onConfirmation}
        />
      </CustomModalComponent>
      <CustomDrawerComponent
        extraClass="orderDetails"
        label={"Order Information"}
        onOpen={showGenerateModal}
        ontoggle={() => setShowGenerateModal(false)}
        drawerSizing={productContent.length > 0 ? 1000 : 500}
      >
        <OrdersViewModal
          productContent={productContent}
          orderContent={merchantData}
        />
      </CustomDrawerComponent>
    </Card>
  );
};
export default LinkTableComponent;
