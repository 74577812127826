export default {
  SCREEN_XXXL_MIN: "@media (min-width: 1601px)",
  SCREEN_XXXL_MAX: "@media (max-width: 1600px)",
  SCREEN_XXL_MIN: "@media (min-width: 1401px)",
  SCREEN_XXL_MAX: "@media (max-width: 1400px)",
  SCREEN_XXLM_MIN: "@media (min-width: 1367px)",
  SCREEN_XXLM_MAX: "@media (max-width: 1366px)",
  SCREEN_XL_MIN: "@media (min-width: 1201px)",
  SCREEN_XL_MAX: "@media (max-width: 1200px)",
  SCREEN_LG_MIN: "@media (min-width: 993px)",
  SCREEN_LG_MAX: "@media (max-width: 992px)",
  SCREEN_MD_MIN: "@media (min-width: 769px)",
  SCREEN_MD_MAX: "@media (max-width: 768px)",
  SCREEN_MDS_MIN: "@media (min-width: 641px)",
  SCREEN_MDS_MAX: "@media (max-width: 640px)",
  SCREEN_SM_MIN: "@media (min-width: 576px)",
  SCREEN_SM_MAX: "@media (max-width: 575px)",
  SCREEN_XS_MIN: "@media (min-width: 481px)",
  SCREEN_XS_MAX: "@media (max-width: 480px)",

  SCREEN_XXXL_MIN_MAX:
    "@media only screen and (min-width: 1400px) and (max-width: 1601px) ",
  SCREEN_XXL_MIN_MAX:
    "@media only screen and (min-width: 1200px) and (max-width: 1401px) ",
  SCREEN_XL_MIN_MAX:
    "@media only screen and (min-width: 992px) and (max-width: 1201px) ",
  SCREEN_LG_MIN_MAX:
    "@media only screen and (min-width: 768px) and (max-width: 993px) ",
  SCREEN_MD_MIN_MAX:
    "@media only screen and (min-width: 640px) and (max-width: 769px) ",
  SCREEN_SM_MIN_MAX:
    "@media only screen and (min-width: 320px) and (max-width: 641px) ",
  SCREEN_DASK_MIN_MAX:
    "@media only screen and (min-width: 1366px) and (max-width: 1920px) ",
  SCREEN_DASKSM_MIN_MAX:
    "@media only screen and (min-width: 992px) and (max-width: 1366px) ",
  SCREEN_LAPTOP_MIN_MAX:
    "@media only screen and (min-width: 768px) and (max-width: 1024px) ",
};
