import React from "react";
import propTypes from "prop-types";
import classnames from "classnames";
import { useStyles } from "./style";
const ButtonComponent = ({
  children,
  onClick,
  className,
  disabled,
  type,
  title,
  bgColor,
  color,
  btnLink,
  btnPrimary,
  btnPrimaryFill,
  btnSecondary,
  btnSecondaryOutline,
  btnSuccess,
  btnDanger,
  btnWarning,
  btnLight,
  btnInfo,
  btnLg,
  btnSm,
  extraClass,
  width,
  style,
  preFixIcon,
  postFixIcon,
  peNone,
  buttonRef,
  theme,
  loading,
  ...rest
}) => {
  const classes = useStyles({ width });
  return (
    <button
      disabled={loading ? loading : disabled}
      title={title}
      type={type}
      ref={buttonRef}
      className={classnames(
        classes.btn,
        className,
        extraClass,
        btnLink && classes.btnLink,
        btnPrimary && classes.btnPrimary,
        btnPrimaryFill && classes.btnPrimaryFill,
        btnSecondary && classes.btnSecondary,
        btnSecondaryOutline && classes.btnSecondaryOutline,
        btnSuccess && classes.btnSuccess,
        btnDanger && classes.btnDanger,
        btnWarning && classes.btnWarning,
        btnInfo && classes.btnInfo,
        btnLight && classes.btnLight,
        btnLg && classes.btnLg,
        btnSm && classes.btnSm
      )}
      onClick={onClick}
      style={style}
      {...rest}
    >
      {preFixIcon}
      {children}
      {postFixIcon}
    </button>
  );
};
export default ButtonComponent;
