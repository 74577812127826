import React, { useEffect, useState } from "react";
import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { getStorageData } from "utils/helper";
import "./index.css";
import POSComponent from "components/POS/POSComponent";

const POSContainer = () => {
  const [showLoader, setShowLoader] = useState(false);

  // const manageUsersData = (getWalletEmail) => {
  //   try {
  //     getUsers().then((response) => {
  //       const responseData = response.data || response;
  //       if (responseData !== null && responseData.success !== false) {
  //         if (responseData.length === 0) {
  //           console.log("You dont have any profile");
  //           setShowLoader(false);
  //         } else {
  //           setShowLoader(false);
  //           // setUserId(responseData.user_id);
  //         }
  //       } else {
  //         setShowLoader(false);
  //       }
  //     });
  //   } catch (error) {}
  // };

  useEffect(() => {
    const getWalletEmail = getStorageData("pocketpay:email");
    // getWalletEmail !== null && manageUsersData(getWalletEmail);
  }, []);

  return (
    <div className="content">
      {showLoader ? (
        <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />
      ) : (
        <POSComponent />
      )}
    </div>
  );
};

export default POSContainer;
