import { toast } from "react-toastify";
import copy from "copy-to-clipboard";
import { Image } from "antd";
import React, { createContext, useContext, useState } from "react";
import BonkIcon from "assets/img/solana_bonk.svg";
import WenIcon from "assets/img/solana_wen.svg";
import {
  SolanaIcon,
  SolanaUsdcIcon,
  SuiIcon,
  BlastIcon,
  PolygonIcon,
  BinanceNewIcon,
  PythIcon,
  JupIcon,
  UsdtIcon,
} from "svgIcon";

export const toastMessage = (data) =>
  toast(data, {
    position: "top-center",
    autoClose: 1000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
  });

export const getStorageData = (key) => {
  try {
    const item = localStorage.getItem(key);
    if (item !== null) {
      return item;
    } else {
      return null;
    }
  } catch (err) {
    return null;
  }
};

export const setStorageData = (key, data) => {
  // const dataTemp = JSON.stringify(data);
  localStorage.setItem(key, data);
};

export const removeStorageItem = (key) => {
  localStorage.removeItem(key);
};

const GlobalMerchantContext = createContext();

export function GlobalMerchantProvider({ children }) {
  const [globalMerchantId, setGlobalMerchantId] = useState(null);
  const [haveReloadGlobal, setHaveRelaodGlobal] = useState(false);
  return (
    <GlobalMerchantContext.Provider
      value={{
        globalMerchantId,
        setGlobalMerchantId,
        setHaveRelaodGlobal,
        haveReloadGlobal,
      }}
    >
      {children}
    </GlobalMerchantContext.Provider>
  );
}

export function useGlobalMerchant() {
  const context = useContext(GlobalMerchantContext);
  if (context === undefined) {
    throw new Error(
      "useGlobalMerchant must be used within a GlobalMerchantProvider"
    );
  }
  return context;
}

export const ChainSUI = "SUI";
export const ChainBlast = "Blast";
export const ChainAptos = "Aptos";
export const ChainVenom = "Venom";
export const ChainPolygon = "Polygon";
export const ChainBNB = "BNB";
export const ChainEVM = "EVM";
export const ChainSolana = "Solana";
export const ChainSolanaUSDC = "SOL-USDC";
export const ChainSOL = "SOL";
export const ChainSolanaUSDT = "USDT";
export const ChainSolanaPYTH = "PYTH";
export const ChainSolanaBONK = "BONK";
export const ChainSolanaWEN = "WEN";
export const ChainSolanaJUP = "JUP";
export const ChainICP = "ICP";
export const ChainCKBTC = "ckBTC";
export const ChainCKTestBTC = "ckTESTBTC";

export const checkCoin = (getCoinName, getDigest) => {
  switch (getCoinName) {
    case "SUI": {
      return `https://suiexplorer.com/txblock/${getDigest}?network=testnet`;
    }
    case "Blast": {
      return `https://testnet.blastscan.io/tx/${getDigest}`;
    }
    case "SOL": {
      return `https://solscan.io/tx/${getDigest}`;
    }
    case "SOL-USDC": {
      return `https://solscan.io/tx/${getDigest}`;
    }
    case "USDT": {
      return `https://solscan.io/tx/${getDigest}`;
    }
    case "PYTH": {
      return `https://solscan.io/tx/${getDigest}`;
    }
    case "BONK": {
      return `https://solscan.io/tx/${getDigest}`;
    }
    case "WEN": {
      return `https://solscan.io/tx/${getDigest}`;
    }
    case "JUP": {
      return `https://solscan.io/tx/${getDigest}`;
    }
    case "Polygon": {
      return `https://mumbai.polygonscan.com/tx/${getDigest}`;
    }
    case "BNB": {
      return `https://testnet.bscscan.com/tx/${getDigest}`;
    }
  }
};

export const copyContent = (textToCopy) => {
  copy(textToCopy, {
    debug: true,
    message: "Press #{key} to copy",
  });
  toastMessage("Copied!");
};

export const validateAddress = (address) => {
  const aptosRegex = /^0x[a-fA-F0-9]{64}$/;
  const venomRegex = /^0:[a-fA-F0-9]{64}$/;
  const solanaRegex = /^[1-9A-HJ-NP-Za-km-z]{32,44}$/;
  const icpRegex = /^[a-z2-7]{5}(-[a-z2-7]{5})*(-[a-z2-7]+)?$/;
  const evmRegex = /^0x[a-fA-F0-9]{40}$/; // Regex for EVM compatible addresses

  if (aptosRegex.test(address)) {
    return ChainAptos;
  } else if (venomRegex.test(address)) {
    return ChainVenom;
  } else if (solanaRegex.test(address)) {
    return ChainSolana;
  } else if (icpRegex.test(address)) {
    return ChainCKTestBTC;
  } else if (evmRegex.test(address)) {
    return ChainEVM;
  } else {
    return false;
  }
};

export const selectedChainIcon = (getCoinName) => {
  switch (getCoinName) {
    case "Blast": {
      return <BlastIcon />;
    }
    case "SUI": {
      return <SuiIcon />;
    }
    case "Solana": {
      return <SolanaIcon />;
    }
    case "SOL": {
      return <SolanaIcon />;
    }
    case "SOL-USDC": {
      return <SolanaUsdcIcon />;
    }
    case "USDT": {
      return <UsdtIcon />;
    }
    case "JUP": {
      return <JupIcon />;
    }
    case "PYTH": {
      return <PythIcon />;
    }
    case "BONK": {
      return <Image src={BonkIcon} preview={false} alt="bonk icon" />;
    }
    case "WEN": {
      return <Image src={WenIcon} preview={false} alt="wen icon" />;
    }
    case "BNB": {
      return <BinanceNewIcon />;
    }
    case "Polygon": {
      return <PolygonIcon />;
    }
  }
};

export const checkAddress = (getCoinName, getAddress) => {
  switch (getCoinName) {
    case "SUI": {
      return `https://suiexplorer.com/address/${getAddress}?network=testnet`;
    }
    case "Blast": {
      return `https://testnet.blastscan.io/address/${getAddress}`;
    }
    case "Aptos": {
      return `https://explorer.aptoslabs.com/account/${getAddress}?network=testnet`;
    }
    case "Venom": {
      return `https://testnet.venomscan.com/accounts/${getAddress}`;
    }
    case "SOL": {
      return `https://solscan.io/account/${getAddress}?cluster=devnet`;
    }
    case "SOL-USDC": {
      return `https://solscan.io/account/${getAddress}?cluster=devnet`;
    }
    case "ckBTC": {
      return `https://dashboard.internetcomputer.org/bitcoin/transaction/${getAddress}`;
    }
    case "Polygon": {
      return `https://mumbai.polygonscan.com/address/${getAddress}`;
    }
    case "BNB": {
      return `https://testnet.bscscan.com/address/${getAddress}`;
    }
    default:
      return `Something went wrong`;
  }
};

// export function useGlobalMerchant() {
//   const context = useContext(GlobalMerchantContext);
//   if (context === undefined) {
//     throw new Error(
//       "useGlobalMerchant must be used within a GlobalMerchantProvider"
//     );
//   }
//   return context;
// }

export function shortenAddress(text, start = 5, end = 3, separator = "...") {
  return !text
    ? ""
    : text.slice(0, start) + separator + (end ? text.slice(-end) : "");
}

const isEmpty = (value) =>
  value === undefined || value === null || value === "";

export const emailValidationExp = new RegExp(
  /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/
);

export function Email(value) {
  // Let's not start a debate on email regex. This is just for an example app!

  // eslint-disable-next-line no-use-before-define
  if (!isEmpty(value) && !emailValidationExp.test(value)) {
    // eslint-disable-line
    return !isEmpty(value) && !emailValidationExp.test(value); // eslint-disable-line
  }
  return false;
}
