import { Table } from "antd";
import React from "react";
import { Card, CardBody } from "reactstrap";

const DashTableComponent = ({ orderList }) => {
  const columns = [
    {
      title: "SR No",
      dataIndex: "sr_no",
      key: "sr_no",
    },
    {
      title: "Amount (USD)",
      dataIndex: "amount",
      key: "amount",
    },
    {
      title: "Date Time (UTC)",
      dataIndex: "date_time",
      key: "date_time",
    },
    {
      title: "Customer Email",
      dataIndex: "customer_email",
      key: "customer_email",
    },
    {
      title: "Order ID",
      dataIndex: "payment_id",
      key: "payment_id",
    },
    {
      title: "Type",
      dataIndex: "order_type",
      key: "order_type",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      align: "center",
    },
  ];

  const responseFormate = (data) => {
    return (
      data &&
      data.map((item, index) => {
        return {
          key: index,
          sr_no: index + 1,
          amount: item.amount === null ? "-" : item.amount,
          date_time: item.status === "completed" ? item.date_time : "-",
          customer_email:
            item.customer_email == null || item.customer_email == " "
              ? "-"
              : item.customer_email,
          payment_id: item.status === "completed" ? item.payment_id : "-",
          order_type: item.order_type,
          status: <span className="badge badge-success">{item.status}</span>,
        };
      })
    );
  };
  return (
    <Card>
      <CardBody>
        <Table dataSource={responseFormate(orderList)} columns={columns} />
      </CardBody>
    </Card>
  );
};

export default DashTableComponent;
