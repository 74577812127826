import React, { useState } from "react";
import { useStyles } from "./style";
import classNames from "classnames";
import imgLogo from "assets/img/logo.svg";
import LRFimg from "assets/img/ppLRFimg.svg";
import LoginComponent from "components/Sign/LoginComponent";
import RegisterComponent from "components/Sign/RegisterComponent";
import ForgetPasswordComponent from "components/Sign/ForgotPassword/ForgetPasswordComponent";

const SignContainer = ({ setWalletEmail }) => {
  const classes = useStyles();
  const [showRegister, setShowRegister] = useState(false);
  const [showForgetPassword, setShowForgetPassword] = useState(false);
  return (
    <div
      className={classNames(
        classes.accountWrap,
        "accountWrapCommon d-flex p-2"
      )}
    >
      <div className="acLeft d-flex flex-column justify-content-between">
        <div className="acLT d-flex flex-column justify-content-between align-items-center text-center">
          <img
            className="imgLogo"
            width="190"
            alt="PocketPay"
            title="PocketPay"
            src={imgLogo}
          />
          <img className="LRFimg" alt="Image" src={LRFimg} />
          <h3>
            The most reputable, friendly, <br />
            and secure crypto wallet.
          </h3>
          <p className="mb-3 mb-md-5">
            It has survived not only five centuries, but also the leap <br />
            into electronic typesetting, remaining.
          </p>
        </div>
        <div className="acLB text-center">
          <p className="m-0 text-uppercase ">
            Buy, Store, Send and Swap Tokens
          </p>
        </div>
      </div>
      <div className="acRight">
        <div className="acRBox m-auto">
          {showForgetPassword ? (
            <ForgetPasswordComponent
              setShowRegister={setShowRegister}
              setShowForgetPassword={setShowForgetPassword}
              setWalletEmail={setWalletEmail}
            />
          ) : (
            <>
              {showRegister ? (
                <RegisterComponent setShowRegister={setShowRegister} />
              ) : (
                <LoginComponent
                  setShowForgetPassword={setShowForgetPassword}
                  setShowRegister={setShowRegister}
                  setWalletEmail={setWalletEmail}
                />
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default SignContainer;
