import React from "react";
import { useEffect, useState } from "react";
import { Select, Tooltip } from "antd";
import { SuiClient, getFullnodeUrl } from "@mysten/sui.js/client";
import { isValidSuiAddress } from "@mysten/sui.js/utils";
import { LoadingOutlined } from "@ant-design/icons";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FormGroup, Input } from "reactstrap";
import {
  validateAddress,
  toastMessage,
  useGlobalMerchant,
  ChainAptos,
  ChainVenom,
  ChainSolana,
  ChainCKTestBTC,
  ChainSUI,
  ChainEVM,
} from "utils/helper";
import {
  createMerchantAddress,
  updateMerchantAddress,
} from "apiServices/setting";
import ButtonComponent from "components/FormComponents/ButtonComponent";

export const client = new SuiClient({
  url: getFullnodeUrl(`testnet`),
});

const ChainFormComponent = ({
  chainData,
  blockChainList,
  merchantBlockChainList,
  setShowFormModal,
  onComplete,
  formFormat,
  isEditId,
  isEditObject,
}) => {
  const { globalMerchantId } = useGlobalMerchant();
  const [onBtnLoader, setOnBtnLoader] = useState(false);
  const [inputFields, setInputFields] = useState({
    blockchain_name: "",
    wallet_address: "",
  });

  const resolveNameService = async (name) => {
    try {
      const address = await client.resolveNameServiceAddress({ name });
      return address;
    } catch (e) {
      return null;
    }
  };

  const managePutChain = (getId, getBlockchainList) => {
    const blockchainItem = getBlockchainList.find(
      (item) => item.blockchain_id === getId
    );
    if (blockchainItem) {
      const subCoinIds = [];
      subCoinIds.push(blockchainItem.id);
      return subCoinIds;
    } else {
      for (const item of getBlockchainList) {
        if (item.sub_coin && item.sub_coin.length > 0) {
          const subCoinResult = managePutChain(getId, item.sub_coin);
          if (subCoinResult.length > 0) {
            return subCoinResult;
          }
        }
      }
      console.log("Blockchain Item not found");
      return [];
    }
  };

  const managePostChain = (getName) => {
    const blockchainItem = blockChainList.find(
      (item) => item.blockchain_name === getName
    );
    if (blockchainItem) {
      const subCoinName = [];
      if (blockchainItem.sub_coin && blockchainItem.sub_coin.length > 0) {
        blockchainItem.sub_coin.map((subCoin) =>
          subCoinName.push(subCoin.blockchain_name)
        );
        subCoinName.push(blockchainItem.blockchain_name);
        return subCoinName;
      } else {
        subCoinName.push(blockchainItem.blockchain_name);
        return subCoinName;
      }
    } else {
      console.log("Blockchain Item not found");
      return [];
    }
  };

  const onStoreChainFunction = async () => {
    setOnBtnLoader(true);
    const transformedData = [];
    const data = await managePostChain(inputFields.blockchain_name);
    for (let i = 0; i < data.length; i++) {
      transformedData.push({
        merchant_id: globalMerchantId,
        blockchain_name: data[i],
        wallet_address: inputFields.wallet_address,
      });
    }
    try {
      createMerchantAddress(transformedData)
        .then((response) => {
          toastMessage("success");
          setOnBtnLoader(false);
          setShowFormModal(false);
          onComplete();
        })
        .catch((error) => {});
    } catch (error) {
      setOnBtnLoader(false);
      toastMessage("something went wrong");
    }
  };

  const onUpdateChainFunction = async () => {
    if (isEditId !== "") {
      setOnBtnLoader(true);
      const data = await managePutChain(isEditId, merchantBlockChainList);
      const transformedData = [];
      for (let i = 0; i < data.length; i++) {
        transformedData.push({
          id: data[i],
          wallet_address: inputFields.wallet_address,
        });
      }
      try {
        updateMerchantAddress(transformedData)
          .then((response) => {
            toastMessage("updated successfully");
            setOnBtnLoader(false);
            setShowFormModal(false);
            onComplete();
          })
          .catch((error) => {});
      } catch (error) {
        setOnBtnLoader(false);
        toastMessage("something went wrong");
      }
    }
  };

  const onAddressVerification = async () => {
    if (
      inputFields.wallet_address !== "" &&
      inputFields.blockchain_name !== ""
    ) {
      if (inputFields.blockchain_name === ChainSUI) {
        if (inputFields.wallet_address.includes(".sui")) {
          let getAddress = await resolveNameService(inputFields.wallet_address);
          if (!getAddress) {
            toastMessage("Is not a valid sns name");
            return;
          } else {
            formFormat === "add"
              ? onStoreChainFunction()
              : onUpdateChainFunction();
          }
        } else {
          const isSuiAddress = isValidSuiAddress(inputFields.wallet_address);
          if (isSuiAddress) {
            formFormat === "add"
              ? onStoreChainFunction()
              : onUpdateChainFunction();
          } else {
            toastMessage("Is not a valid sui address");
            return;
          }
          return;
        }
      } else {
        const isValidAddress = validateAddress(inputFields.wallet_address);
        if (
          isValidAddress === ChainAptos ||
          isValidAddress === ChainVenom ||
          isValidAddress === ChainSolana ||
          isValidAddress === ChainCKTestBTC ||
          isValidAddress === ChainEVM
        ) {
          formFormat === "add"
            ? onStoreChainFunction()
            : onUpdateChainFunction();
        } else {
          toastMessage("Is not a valid address");
          return;
        }
      }
    } else {
      toastMessage("All fields are required!");
    }
  };

  const manageChainOptionList = () => {
    if (Object.keys(chainData).length > 0 && isEditObject) {
      setInputFields({
        ...inputFields,
        blockchain_name: isEditObject.edit_name,
        wallet_address: isEditObject.edit_address,
      });
    }
  };

  useEffect(() => {
    formFormat === "edit" && manageChainOptionList();
  }, []);

  return (
    <div className="content">
      <FormGroup>
        <label>
          Chain name<span className="text-danger ml-1">*</span>
        </label>
        <Select
          placeholder="Select Chain"
          className="w-100"
          value={inputFields.blockchain_name}
          onChange={(e) =>
            setInputFields({
              ...inputFields,
              blockchain_name: e,
            })
          }
          options={
            chainData &&
            chainData.map((chain) => ({
              value: chain.blockchain_name,
              label: chain.blockchain_name,
            }))
          }
          disabled={formFormat === "edit" && true}
        />
      </FormGroup>
      <FormGroup>
        <label>
          Wallet address<span className="text-danger ml-1">*</span>
        </label>
        <Input
          type="text"
          placeholder="Wallet address"
          name="wallet_address"
          value={inputFields.wallet_address}
          onChange={(e) =>
            setInputFields({
              ...inputFields,
              wallet_address: e.target.value,
            })
          }
        />
      </FormGroup>
      <hr className="my-4" />
      <div className="text-right mt-3">
        <Tooltip title="Update Settings" className="cursorPointer">
          <ButtonComponent
            btnPrimary
            extraClass="svgAnimationYX ml-2"
            onClick={() => !onBtnLoader && onAddressVerification()}
          >
            {formFormat === "add" ? "Save" : "Update"}
            {onBtnLoader ? (
              <LoadingOutlined className="spinLader ml-2" />
            ) : (
              <FontAwesomeIcon
                style={{ width: "16", height: "16" }}
                className="aniArrow ml-2"
                icon={faArrowRight}
              />
            )}
          </ButtonComponent>
        </Tooltip>
      </div>
    </div>
  );
};

export default ChainFormComponent;
