import React from "react";
import { Col, Row } from "antd";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CheckoutComponent from "components/Setting/CheckoutComponent";
import PMerchantComponent from "components/Setting/PMerchantComponent";
import PTerminalComponent from "components/Setting/PTerminalComponent";
import GeneralComponent from "components/Setting/GeneralComponent";
import ChainListComponent from "components/Setting/Chain/ChainListComponent";

const SettingContainer = () => {
  return (
    <div className="content">
      <ToastContainer />
      <Row gutter={{ xs: 20, sm: 20, md: 20, lg: 20 }}>
        <Col
          className="gutter-row mb-4"
          span={12}
          xs={{ span: 24 }}
          sm={{ span: 24 }}
          md={{ span: 12 }}
        >
          <GeneralComponent />
        </Col>
        <Col
          className="gutter-row mb-4"
          span={12}
          xs={{ span: 24 }}
          sm={{ span: 24 }}
          md={{ span: 12 }}
        >
          <CheckoutComponent />
        </Col>
        <Col
          className="gutter-row mb-4"
          span={12}
          xs={{ span: 24 }}
          sm={{ span: 24 }}
          md={{ span: 12 }}
        >
          <PMerchantComponent />
        </Col>
        <Col
          className="gutter-row mb-4"
          span={12}
          xs={{ span: 24 }}
          sm={{ span: 24 }}
          md={{ span: 12 }}
        >
          <PTerminalComponent />
        </Col>
        <Col
          className="gutter-row mb-4"
          span={24}
          xs={{ span: 24 }}
          sm={{ span: 24 }}
          md={{ span: 24 }}
        >
          <ChainListComponent />
        </Col>
      </Row>
    </div>
  );
};

export default SettingContainer;
